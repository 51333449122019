import { Dictionary } from 'lodash';
import { atomFamily, selectorFamily } from 'recoil';

import { IQuestion, IQuestionnaire } from '../api';

export type IQuestionState = {
  questionIndex: number;
};

export type IQuestionWithState = IQuestion & IQuestionState;

export interface IQuestionnaireState extends IQuestionnaire {
  totalQuestionCount: number;
  questions: IQuestionWithState[];
  questionsById: Dictionary<IQuestionWithState>;
}

export const questionnaireAtomFamily = atomFamily<
  IQuestionnaireState | undefined,
  string
>({
  key: 'questionnaire',
  default: undefined,
});

export const selectQuestionnaire = selectorFamily<IQuestionnaireState, string>({
  key: 'questionnaireSelector',
  get:
    (questionnaireId: string) =>
    ({ get }) => {
      const questionnaire = get(questionnaireAtomFamily(questionnaireId));
      if (!questionnaire) throw new Error('Questionnaire not found in state');
      return questionnaire;
    },
});

export const selectQuestionById = selectorFamily<
  IQuestion,
  { questionnaireId: string; questionId: string }
>({
  key: 'questionByIdSelector',
  get:
    ({ questionnaireId, questionId }) =>
    ({ get }) => {
      const questionnaire = get(selectQuestionnaire(questionnaireId));
      return questionnaire.questionsById[questionId];
    },
});

export const selectQuestionProgressById = selectorFamily<
  | { questionIndex: number; totalQuestionCount: number }
  | { questionIndex: undefined; totalQuestionCount: undefined },
  { questionnaireId: string; questionId: string }
>({
  key: 'questionProgressByIdSelector',
  get:
    ({ questionnaireId, questionId }) =>
    ({ get }) => {
      if (!questionId) {
        return {
          questionIndex: undefined,
          totalQuestionCount: undefined,
        };
      }
      const questionnaire = get(selectQuestionnaire(questionnaireId));
      const { questionIndex } = questionnaire.questionsById[questionId];
      return {
        questionIndex,
        totalQuestionCount: questionnaire.totalQuestionCount,
      };
    },
});
