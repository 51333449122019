import { useRecoilValue } from 'recoil';

import { patientIdSelector } from 'app/state';

const usePatientId = () => {
  const patientId = useRecoilValue(patientIdSelector);
  if (!patientId) throw new Error('Patient id not found in state');
  return patientId;
};

export default usePatientId;
