import { Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Avo, { TasksValueType } from 'analytics/Avo';
import usePatientName from 'app/hooks/usePatientName';
import usePatientTodoItems from 'app/hooks/usePatientTodoItems';
import { linkToSubmitBP } from 'app/routing/links';
import { loggedInPatientInteractionsSelector } from 'app/state';
import { IAnySubmitBpTask, IInteractionPopulated } from 'app/suvera-api';
import { linkToQuestionnaire } from 'pages/Questionnaire/links';

import CellTimePriority from './CellTimePriority';
import CheckIcon from './CheckIcon';
import ConfirmBloodPressureSubmissionDialogue from './ConfirmBloodPressureSubmissionDialogue';

function hasBPReadings(interaction: IInteractionPopulated) {
  if (interaction.clinical_readings === undefined) {
    return false;
  }
  return (
    interaction.clinical_readings[0] &&
    interaction.clinical_readings[0][0] &&
    [
      'blood_pressure_systole',
      'blood_pressure_diastole',
      'pulse_rate',
    ].includes(interaction.clinical_readings[0][0].metric)
  );
}

function isClinicialSubmission(interaction: IInteractionPopulated) {
  return [
    'clinician_email_clinical_submission',
    'clinician_phone_clinical_submission',
    'clinician_sms_clinical_submission',
  ].includes(interaction.interaction_type._id);
}

function isBPSubmission(interaction: IInteractionPopulated) {
  const isWebform1 =
    interaction.interaction_type._id === 'webform_1_clinical_submission';
  return (
    isWebform1 ||
    (isClinicialSubmission(interaction) && hasBPReadings(interaction))
  );
}

function numberOfBPSubmissions(interactions: IInteractionPopulated[]) {
  return interactions.filter((i) => isBPSubmission(i)).length;
}

function getNumberOfBPSubmissionsSinceDate(
  interactions: IInteractionPopulated[],
  startDate: Date,
) {
  const interactionsSinceBPStartDate = interactions.filter(
    (i) => new Date(i.datetime).getTime() > new Date(startDate).getTime(),
  );

  return numberOfBPSubmissions(interactionsSinceBPStartDate);
}

const getTimeOfMostRecentBloodPressureSubmissionFromInteractions = (
  interactionsLog: IInteractionPopulated[],
): Date | undefined => interactionsLog?.find(isBPSubmission)?.datetime;

const hasSubmittedBloodPressureReadingsRecently = (
  interactionsLog: IInteractionPopulated[],
) => {
  const timeOfLastBloodPressureReading =
    getTimeOfMostRecentBloodPressureSubmissionFromInteractions(interactionsLog);
  if (!timeOfLastBloodPressureReading) return false;
  const fiveMinutesInMs = 5 * 60 * 1000;
  const msSinceLastReading =
    Date.now() - new Date(timeOfLastBloodPressureReading).getTime();
  return msSinceLastReading < fiveMinutesInMs;
};

const BloodPressureFromTaskTodo: FC<{
  startBloodPressureSubmission: () => void;
  task: IAnySubmitBpTask;
  interactions: IInteractionPopulated[];
}> = ({ task, startBloodPressureSubmission, interactions }) => {
  const timeOfLastBloodPressureReading =
    getTimeOfMostRecentBloodPressureSubmissionFromInteractions(interactions);

  const bloodPressureCount = timeOfLastBloodPressureReading
    ? getNumberOfBPSubmissionsSinceDate(interactions, task.start)
    : 0;

  return (
    <CellTimePriority
      text="Record your blood pressure"
      time="5"
      required
      onClickStart={startBloodPressureSubmission}
      readingNumber={bloodPressureCount + 1}
      numberOfSetsRequested={task.numberOfSetsRequested}
    />
  );
};

const HomeTab: FC = () => {
  const navigate = useNavigate();

  const interactionsLog = useRecoilValue(loggedInPatientInteractionsSelector);

  const patientName = usePatientName();
  const todoItems = usePatientTodoItems();

  const startBloodPressureSubmission = () => {
    navigate(linkToSubmitBP());
  };

  useEffect(() => {
    const tasks: TasksValueType[] = [];
    if (todoItems.bloodPressure) {
      tasks.push('submit_bp');
    }

    if (todoItems.lifestyle) {
      tasks.push('questionnaire_lifestyle');
    }

    if (todoItems.asthma) {
      tasks.push('questionnaire_asthma');
    }

    Avo.taskListViewed({ tasks });
  }, [todoItems]);

  const onStartLifestyleSubmission = () => {
    Avo.questionnaireStarted({ questionnaireType: 'lifestyle' });
    navigate(linkToQuestionnaire('lifestyle'));
  };

  const onStartAsthmaSubmission = () => {
    Avo.questionnaireStarted({ questionnaireType: 'asthma' });
    navigate(linkToQuestionnaire('asthma'));
  };

  const [
    showConfirmBloodPressureSubmissionDialogue,
    setConfirmBloodPressureModalVisibility,
  ] = useState(false);

  const checkBeforeStartingBloodPressureSubmission = () => {
    if (hasSubmittedBloodPressureReadingsRecently(interactionsLog || [])) {
      setConfirmBloodPressureModalVisibility(true);
    } else {
      startBloodPressureSubmission();
    }
  };

  const allItemsComplete =
    !todoItems.bloodPressure && !todoItems.lifestyle && !todoItems.asthma;

  if (allItemsComplete) {
    return (
      <Stack spacing="16px" paddingBottom="22px">
        <Typography variant="h2" component="h1">
          Hello {patientName}
        </Typography>

        <Typography>Welcome to your patient hub!</Typography>

        <Stack
          bgcolor="colors.greyLightest"
          padding="16px"
          borderRadius="4px"
          spacing="16px"
          alignItems="center"
        >
          <CheckIcon />
          <Typography variant="h3" component="h2">
            All done, for now!
          </Typography>
          <Typography textAlign="center">
            Our team will look over your answers and organise your appointment
            soon.
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing="16px" paddingBottom="22px">
        <Typography variant="h2" component="h1">
          Hello {patientName},
        </Typography>
        <Typography>Let’s begin managing your health remotely.</Typography>
        <Typography>
          Click on any section in your to-do list to start sharing information
          for your next review.
        </Typography>
      </Stack>

      <Typography variant="h3" component="h2">
        Your to-do list
      </Typography>

      {todoItems.bloodPressure ? (
        <BloodPressureFromTaskTodo
          startBloodPressureSubmission={
            checkBeforeStartingBloodPressureSubmission
          }
          task={todoItems.bloodPressure}
          interactions={interactionsLog || []}
        />
      ) : null}

      {todoItems.lifestyle ? (
        <CellTimePriority
          text="Fill in your lifestyle questionnaire"
          time="2"
          required
          onClickStart={onStartLifestyleSubmission}
        />
      ) : null}

      {todoItems.asthma ? (
        <CellTimePriority
          text="Fill in your asthma questionnaire"
          time="5"
          required
          onClickStart={onStartAsthmaSubmission}
        />
      ) : null}

      <ConfirmBloodPressureSubmissionDialogue
        visible={showConfirmBloodPressureSubmissionDialogue}
        onCancel={() => {
          setConfirmBloodPressureModalVisibility(false);
        }}
        onContinue={() => {
          setConfirmBloodPressureModalVisibility(false);
          startBloodPressureSubmission();
        }}
      />
    </>
  );
};

export default HomeTab;
