import { useRecoilValue } from 'recoil';

import { patientDetailsSelector } from 'app/state';
import { IAnySubmitBpTask, ISubmitFormTask, ITask } from 'app/suvera-api';

const isStartedTask = (task: ITask) =>
  new Date(task.start).getTime() <= Date.now();

const isSubmitBpTask = (task: ITask): task is IAnySubmitBpTask =>
  task.task_type_id === 'submit_bp' || task.task_type_id === 'submit_triage_bp';

const isStartedSubmitBpTask = (task: ITask): task is IAnySubmitBpTask =>
  isStartedTask(task) && isSubmitBpTask(task);

const isFormTask = (task: ITask): task is ISubmitFormTask =>
  task.task_type_id === 'submit_form';

const isStartedLifestyleQuestionnaireTask = (task: ITask) =>
  isStartedTask(task) && isFormTask(task) && task.formType === 'lifestyle';

const isStartedAsthmaQuestionnaireTask = (task: ITask) =>
  isStartedTask(task) && isFormTask(task) && task.formType === 'asthma';

const usePatientTodoItems = () => {
  const patient = useRecoilValue(patientDetailsSelector);
  if (!patient) throw new Error('Patient not found in state');

  const patientActiveTasks = patient.active_tasks;

  const asthmaQuestionnaireTask = patientActiveTasks.find((task) =>
    isStartedAsthmaQuestionnaireTask(task),
  );
  const submitBloodPressureTask = patientActiveTasks.find((task) =>
    isStartedSubmitBpTask(task),
  ) as IAnySubmitBpTask;
  const lifestyleQuestionnaireTask = patientActiveTasks.find((task) =>
    isStartedLifestyleQuestionnaireTask(task),
  );

  return {
    asthma: asthmaQuestionnaireTask,
    lifestyle: lifestyleQuestionnaireTask,
    bloodPressure: submitBloodPressureTask,
  };
};

export default usePatientTodoItems;
