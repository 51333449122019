import {
  Alert,
  AlertColor,
  AlertTitle,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  ReactElement,
  useState,
  useEffect,
  useRef,
  KeyboardEventHandler,
} from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Stateful } from '../types';

const ERRONEOUS_UPPER_BOUNDARY = { sys: 300, dia: 150 };
const ERRONEOUS_LOWER_BOUNDARY = { sys: 60, dia: 30 };
const HYPOTENSIVE_UPPER_BOUNDARY = { sys: 90, dia: 50 };

const sanitiseNumericValue = (value: string) => {
  const valueAsInteger = Number.parseInt(value, 10);
  return valueAsInteger >= 0 ? String(valueAsInteger) : '';
};

const preventDecimals: KeyboardEventHandler<HTMLInputElement> = (event) => {
  if (event.key === '.') {
    event.preventDefault();
  }
};

interface ValueSubmissionProps {
  title: string;
  state: Stateful<string>;
  error?: boolean | undefined;
  required: boolean;
}

export const ValueSubmission = ({
  title,
  state,
  error,
  required,
}: ValueSubmissionProps): ReactElement => (
  <FormControl fullWidth required={required}>
    <FormLabel htmlFor={title}>{title}</FormLabel>

    <TextField
      id={title}
      type="number"
      value={state.value || ''}
      error={error || false}
      placeholder="000"
      onChange={(e) => {
        state.setter(sanitiseNumericValue(e.target.value));
      }}
      inputProps={{
        'aria-label': title,
        inputMode: 'numeric',
      }}
      onKeyDown={preventDecimals}
    />
  </FormControl>
);

interface SingleBPSubmissionProps {
  title: string;
  sys: Stateful<string>;
  dia: Stateful<string>;
  pulse: Stateful<string>;
  onValidationUpdate: (valid: boolean) => void;
  testId?: string;
  hasInvalidGap: boolean;
}

const SingleBPSubmission = ({
  onValidationUpdate,
  dia,
  sys,
  title,
  pulse,
  testId = '',
  hasInvalidGap,
}: SingleBPSubmissionProps): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<
    { title: string; body: string; severity: AlertColor } | undefined
  >();
  const [isErroneousData, setIsErroneousData] = useState<boolean>(false);
  const [isValidInput, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    onValidationUpdate(isValidInput);
  }, [isValidInput, onValidationUpdate]);

  const timeoutInterval = 1000;
  const timeout = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    const handleMessage = (
      message?: { title: string; body: string; severity: AlertColor },
      erroneous = false,
    ) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (message === undefined) {
        // Disable linting rule as conflicts with typescript rule
        // eslint-disable-next-line unicorn/no-useless-undefined
        setErrorMessage(undefined);
        setIsErroneousData(erroneous);
        return;
      }
      timeout.current = setTimeout(() => {
        setErrorMessage(message);
        setIsErroneousData(erroneous);
      }, timeoutInterval);
    };
    if (!dia.value || !sys.value) {
      handleMessage();
      setIsValid(false);
    } else if (
      +dia.value >= +sys.value ||
      +sys.value >= ERRONEOUS_UPPER_BOUNDARY.sys ||
      +dia.value >= ERRONEOUS_UPPER_BOUNDARY.dia ||
      +sys.value <= ERRONEOUS_LOWER_BOUNDARY.sys ||
      +dia.value <= ERRONEOUS_LOWER_BOUNDARY.dia ||
      hasInvalidGap
    ) {
      handleMessage(
        {
          title: "Your blood pressure reading doesn't look quite right",
          body: 'Please repeat your measurement.',
          severity: 'warning',
        },
        true,
      );
      setIsValid(false);
    } else {
      if (+sys.value >= 175 || +dia.value >= 115) {
        handleMessage({
          title: 'Your blood pressure reading is very high',
          body: "If you're experiencing a new headache, dizziness, chest pain, palpitations, abdominal pain, sweatiness, confusion or shortness of breath, call 999 now. Otherwise, call 111.",
          severity: 'error',
        });
      } else if (
        (+sys.value > ERRONEOUS_LOWER_BOUNDARY.sys &&
          +sys.value < HYPOTENSIVE_UPPER_BOUNDARY.sys) ||
        (+dia.value > ERRONEOUS_LOWER_BOUNDARY.dia &&
          +dia.value < HYPOTENSIVE_UPPER_BOUNDARY.dia)
      ) {
        handleMessage({
          title: 'Your blood pressure reading is very low',
          body: "If you're experiencing a new headache, dizziness, confusion or shortness of breath, call 999 now. Otherwise, call 111.",
          severity: 'error',
        });
      } else {
        handleMessage();
      }
      setIsValid(true);
    }
  }, [sys.value, dia.value, pulse.value, hasInvalidGap]);

  return (
    <Grid container data-testid={testId} rowSpacing="8px">
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
      </Grid>

      <Grid container item columnSpacing="16px">
        <Grid item xs={4}>
          <ValueSubmission
            title="SYS"
            state={sys}
            error={isErroneousData}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <ValueSubmission
            title="DIA"
            state={dia}
            error={isErroneousData}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <ValueSubmission
            title="PULSE"
            state={pulse}
            error={isErroneousData}
            required={false}
          />
        </Grid>
      </Grid>

      {errorMessage !== undefined && (
        <Grid item xs={12}>
          <Alert
            severity={errorMessage.severity}
            icon={<ErrorIcon fontSize="small" />}
            sx={{ color: 'text.primary' }}
          >
            <AlertTitle sx={{ fontWeight: 'bold' }}>
              {errorMessage.title}
            </AlertTitle>
            {errorMessage.body}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default SingleBPSubmission;
