import { atom, AtomEffect, selector } from 'recoil';

import { IAppointment } from '@suvera/core-types';
import {
  IInteractionPopulated,
  IPatientPopulated,
  SUVERA_API,
} from './suvera-api';
import { loadPersistedSuveraApiAuthToken, persistToken } from './tokens';

const syncTokenInSessionStorage: AtomEffect<SuveraApiAuthToken | undefined> = ({
  onSet,
}) => {
  onSet((newValue: SuveraApiAuthToken | undefined) => {
    if (newValue) {
      persistToken(newValue);
    }
  });
};

const tokenSelector = selector({
  key: 'tokenSelector',
  get: () => loadPersistedSuveraApiAuthToken(),
});

export const suveraApiAuthTokenAtom = atom<SuveraApiAuthToken | undefined>({
  key: 'suveraApiAuthTokenAtom',
  default: tokenSelector,
  effects: [syncTokenInSessionStorage],
});

export const suveraApiAccessTokenSelector = selector<string | undefined>({
  key: 'suveraApiAccessTokenSelector',
  get: ({ get }) => get(suveraApiAuthTokenAtom)?.access_token,
});

export const patientDetailsSelector = selector<IPatientPopulated | undefined>({
  key: 'patientDetailsSelector',
  get: ({ get }) => {
    const token = get(suveraApiAuthTokenAtom)?.access_token;
    if (!token) return;
    // eslint-disable-next-line consistent-return
    return SUVERA_API.getPatientMe(token);
  },
});

export const isPatientLoggedInSelector = selector<boolean>({
  key: 'isPatientLoggedInSelector',
  get: ({ get }) => {
    const loggedInPatient = get(patientDetailsSelector);
    const accessToken = get(suveraApiAccessTokenSelector);
    return !!loggedInPatient && !!accessToken;
  },
});

export const patientIdSelector = selector<string | undefined>({
  key: 'patientIdSelector',
  get: ({ get }) => get(patientDetailsSelector)?._id,
});

export const practiceNameSelector = selector<string | undefined>({
  key: 'practiceNameSelector',
  get: ({ get }) => get(patientDetailsSelector)?.practice?.name,
});

export const patientNameSelector = selector<string | undefined>({
  key: 'patientNameSelector',
  get: ({ get }) => get(patientDetailsSelector)?.name?.first,
});

interface SuveraApiAuthToken {
  access_token: string;
}

export const loggedInPatientInteractionsSelector = selector<
  IInteractionPopulated[] | undefined
>({
  key: 'loggedInPatientInteractionsSelector',
  get: ({ get }) => {
    const patientID = get(patientDetailsSelector)?._id;
    if (!patientID) return;
    const suveraApiAuthToken = get(suveraApiAuthTokenAtom);
    if (!suveraApiAuthToken) return;
    // eslint-disable-next-line consistent-return
    return SUVERA_API.getPatientInteractions(
      patientID,
      suveraApiAuthToken.access_token,
    ).then((result) =>
      result.sort(
        (a, b) =>
          new Date(b.datetime).getTime() - new Date(a.datetime).getTime(),
      ),
    );
  },
});

export const loggedInPatientAppointmentsSelector = selector<
  IAppointment[] | undefined
>({
  key: 'loggedInPatientAppointmentsSelector',
  get: ({ get }) => {
    const patientID = get(patientDetailsSelector)?._id;
    if (!patientID) return;
    const suveraApiAuthToken = get(suveraApiAuthTokenAtom);
    if (!suveraApiAuthToken) return;
    // eslint-disable-next-line consistent-return
    return SUVERA_API.getPatientAppointments(
      patientID,
      suveraApiAuthToken.access_token,
    );
  },
});
