import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import Avo from 'analytics/Avo';
import { IQuestion } from 'pages/Questionnaire/api';
import QuestionnaireProgressBar from 'pages/Questionnaire/components/QuestionnaireProgressBar';
import { useQuestionnaireId } from 'pages/Questionnaire/hooks/routing';
import { useQuestionProgress } from 'pages/Questionnaire/hooks/useQuestionProgress';

import { PropsWithChildren } from 'react';

type Props = {
  question: IQuestion;
  nextStepUrl: string;
};

const QuestionLayout = ({
  children,
  question,
  nextStepUrl,
}: PropsWithChildren<Props>) => {
  const questionnaireId = useQuestionnaireId();
  const { questionIndex, totalQuestionCount } = useQuestionProgress();

  const handleClickSkip = () => {
    Avo.questionnaireStepSkipButtonClicked({
      questionnaireType: questionnaireId,
      stepId: question._id,
      questionIndex,
      totalQuestionCount,
    });
  };

  return (
    <Stack spacing={{ xs: '4px', sm: '12px' }}>
      <QuestionnaireProgressBar stepId={question._id} />
      <Stack spacing={{ xs: '20px', sm: '28px' }}>
        <Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {question.questionText}
            </Typography>

            {question.isRequired ? null : (
              <Button
                component={Link}
                to={nextStepUrl}
                onClick={handleClickSkip}
                variant="text"
                sx={{
                  paddingY: '12px',
                  paddingX: '16px',
                  marginRight: '-16px',
                  alignSelf: 'flex-start',
                }}
              >
                Skip
              </Button>
            )}
          </Box>
          {question.questionType === 'multi_select' && (
            <Typography variant="body2" sx={{ color: 'gray' }}>
              You can select multiple options.
            </Typography>
          )}
        </Stack>

        {children}
      </Stack>
    </Stack>
  );
};

export default QuestionLayout;
