import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { Formik, FormikValues, FieldProps, Field } from 'formik';
import Avo from 'analytics/Avo';
import { IQuestionSelect } from 'pages/Questionnaire/api';
import { useQuestionnaireId } from 'pages/Questionnaire/hooks/routing';
import { useQuestionProgress } from 'pages/Questionnaire/hooks/useQuestionProgress';
import {
  IQuestionnaireAnswers,
  questionnaireAnswersAtom,
} from 'pages/Questionnaire/state';
import BackButton from 'pages/Questionnaire/components/BackButton';
import NextButton from 'pages/Questionnaire/components/NextButton';
import ButtonRow from 'pages/Questionnaire/components/ButtonRow';

import SelectableCard from 'app/components/SelectableCard';
import QuestionLayout from './QuestionLayout';
import useSubmitQuestion from '../hooks/useSubmitQuestion';
import useBackClick from '../hooks/useBackClick';

type Props = {
  question: IQuestionSelect;
  nextStepUrl: string;
};

const getNewFieldValue = (
  currentValue: string | undefined,
  newValue: string,
) => {
  // eslint-disable-next-line unicorn/no-useless-undefined
  if (currentValue && currentValue === newValue) return undefined;
  return newValue;
};

const SingleSelectQuestion = ({ question, nextStepUrl }: Props) => {
  const questionnaireId = useQuestionnaireId();
  const [answers, setAnswers] = useRecoilState(questionnaireAnswersAtom);
  const { questionIndex, totalQuestionCount } = useQuestionProgress();

  const handleClickBack = useBackClick();
  const submit = useSubmitQuestion();

  const handleFormSubmit = (formValues: FormikValues) => {
    const value = formValues[question._id] as IQuestionnaireAnswers[string];
    setAnswers((prev) => ({ ...prev, [question.metric]: value }));
    submit();
  };

  return (
    <QuestionLayout question={question} nextStepUrl={nextStepUrl}>
      <Formik
        // We add a key to make sure Formik re-renders this every time we switch questions rather than cache
        key={question._id}
        onSubmit={handleFormSubmit}
        initialValues={{
          [question._id]: answers[question.metric] || undefined,
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit} style={{ marginTop: '8px' }}>
              <Stack spacing="16px" role="radiogroup">
                {question.options.map(({ text, value, description }) => (
                  <Field key={value} name={question._id}>
                    {(fieldProps: FieldProps) => {
                      return (
                        <SelectableCard
                          text={text}
                          description={description}
                          isSelected={fieldProps.field.value === value}
                          handleClick={async () => {
                            await fieldProps.form.setFieldValue(
                              question._id,
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              getNewFieldValue(fieldProps.field.value, value),
                            );
                            Avo.questionnaireStepOptionSelected({
                              questionnaireType: questionnaireId,
                              stepId: question._id,
                              option: value,
                              questionIndex,
                              totalQuestionCount,
                            });
                          }}
                        />
                      );
                    }}
                  </Field>
                ))}
              </Stack>
              <ButtonRow>
                <BackButton onClick={handleClickBack} />
                <NextButton
                  disable={
                    question.isRequired &&
                    !props.getFieldProps(question._id).value
                  }
                />
              </ButtonRow>
            </form>
          );
        }}
      </Formik>
    </QuestionLayout>
  );
};

export default SingleSelectQuestion;
