import {
  IQuestionnaireCalculatorAnswer,
  questionnaireAnswersAtom,
} from 'pages/Questionnaire/state';
import { useRecoilState } from 'recoil';

export const useAnswerState = (
  metric: string,
): [
  IQuestionnaireCalculatorAnswer,
  (fieldsToSet: IQuestionnaireCalculatorAnswer) => void,
] => {
  const [answers, setAnswers] = useRecoilState(questionnaireAnswersAtom);

  const answer = (answers[metric] || {}) as IQuestionnaireCalculatorAnswer;

  const setAnswer = (fieldsToSet: IQuestionnaireCalculatorAnswer) =>
    setAnswers((prev) => ({
      ...prev,
      [metric]: fieldsToSet,
    }));

  return [answer, setAnswer];
};
