import { useEffect } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { PATHS } from 'app/routing/paths';

import Avo from './Avo';

const routePaths = Object.entries(PATHS).map(([name, path]) => ({
  path,
  name,
}));

const useCurrentPageName = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routePaths, location);

  if (!matchedRoutes || matchedRoutes.length === 0) return null;

  return matchedRoutes[0].route.name;
};

const PageTracker = () => {
  const pageName = useCurrentPageName();

  useEffect(() => {
    if (pageName) {
      Avo.pageViewed({
        customDestinationPageName_: pageName,
      });
    }
  }, [pageName]);

  return null;
};

export default PageTracker;
