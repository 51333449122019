import { Stack, Typography } from '@mui/material';

import PageLayout from 'app/components/PageLayout';
import QuestionnaireHeader from 'pages/Questionnaire/components/QuestionnaireHeader';
import { useQuestionnaire } from 'pages/Questionnaire/hooks/selectors';
import { useGetAdjacentStepUrls } from 'pages/Questionnaire/hooks/steps';

import Avo from 'analytics/Avo';
import { useNavigate } from 'react-router-dom';
import PillStart from './PillStart.svg';

import ECommonStep from '../../types/ECommonStep';
import QuestionnaireCTA from '../../components/QuestionnaireCta';

const QuestionnaireStart = () => {
  const { nextStepUrl } = useGetAdjacentStepUrls(ECommonStep.START);
  const navigate = useNavigate();
  const questionnaire = useQuestionnaire();
  const questionTitles = questionnaire.categories || [];

  const handleClickStart = () => {
    navigate(nextStepUrl);

    Avo.questionnaireStepNextButtonClicked({
      questionnaireType: questionnaire._id,
      stepId: ECommonStep.START,
      questionIndex: undefined,
      totalQuestionCount: undefined,
    });
  };

  return (
    <PageLayout>
      {{
        header: <QuestionnaireHeader stepId={ECommonStep.START} />,
        body: (
          <Stack
            paddingY="16px"
            spacing="32px"
            justifyContent="center"
            textAlign="center"
          >
            <Stack spacing="8px" alignItems="center">
              <img src={PillStart} alt="" height="106px" />
              <Typography component="h1" variant="h2">
                {questionnaire.name}
              </Typography>
              <Typography variant="body2">
                {questionnaire.description}
              </Typography>
            </Stack>
            <Stack spacing="8px">
              <Typography component="h2" variant="body2" fontWeight="bold">
                In this section we&apos;ll ask you about
              </Typography>
              <Stack component="ul" spacing="8px">
                {questionTitles.map((text) => (
                  <Typography component="li" key={text} variant="body2">
                    {text}
                  </Typography>
                ))}
              </Stack>
            </Stack>
            <Typography variant="body2">
              This will take about {questionnaire.estimatedTimeToComplete}{' '}
              minutes to complete
            </Typography>
            <Stack direction="row">
              <QuestionnaireCTA onClick={handleClickStart}>
                Next
              </QuestionnaireCTA>
            </Stack>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default QuestionnaireStart;
