import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
} from '@mui/material/';

import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import PageLayout from '../PageLayout';
import { TabDefinition } from '../TabDefinition';

type Props = {
  pages: TabDefinition[];
  currentTabIndex: number;
};

const BottomTabs = ({ pages, currentTabIndex }: Props) => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      {{
        header: <Header />,
        body: (
          <>
            {pages[currentTabIndex].page}

            <Box height="72px" width="100%" />
            <Box
              sx={{
                bottom: 0,
                left: 0,
                position: 'fixed',
                width: '100%',
              }}
            >
              <Divider />

              <BottomNavigation
                showLabels
                value={currentTabIndex}
                sx={{ height: '72px' }}
              >
                {pages.map((page, i) => (
                  <BottomNavigationAction
                    onClick={() => navigate(page.url)}
                    label={page.title}
                    icon={page.icon(currentTabIndex === i)}
                    key={page.title}
                    sx={{
                      '&.Mui-selected': {
                        borderTopColor: 'colors.blue',
                        ' span': {
                          fontSize: '16px',
                        },
                      },
                      ' span': {
                        fontSize: '16px',
                      },
                      borderTopWidth: '4px',
                      borderTopStyle: 'solid',
                      borderTopColor: 'colors.white',
                      maxWidth: 'unset',
                      color: 'colors.black',
                    }}
                  />
                ))}
              </BottomNavigation>
            </Box>
          </>
        ),
      }}
    </PageLayout>
  );
};

export default BottomTabs;
