import axios from 'axios';
import { z } from 'zod';

import { BASE_URL } from 'app/suvera-api';

const ZQuestionOption = z.object({
  value: z.string(),
  text: z.string(),
  description: z.string().optional(),
});

const ZQuestionSelect = z.object({
  _id: z.string(),
  questionType: z.enum(['single_select', 'multi_select']),
  metric: z.string(),
  questionText: z.string(),
  options: z.array(ZQuestionOption),
  schema: z.unknown(),
  isRequired: z.boolean().optional(),
});

const ZQuestionCalculator = z.object({
  _id: z.string(),
  questionType: z.enum(['height_calculator', 'weight_calculator']),
  metric: z.string(),
  questionText: z.string(),
  isRequired: z.boolean().optional(),
  schema: z.object({
    type: z.literal('number'),
    minimum: z.number(),
    maximum: z.number(),
  }),
});

const ZQuestion = z.union([ZQuestionSelect, ZQuestionCalculator]);

export type IQuestionCalculator = z.infer<typeof ZQuestionCalculator>;
export type IQuestionSelect = z.infer<typeof ZQuestionSelect>;

export type IQuestion = IQuestionCalculator | IQuestionSelect;

const ZQuestionnaire = z.object({
  _id: z.string(),
  name: z.string(),
  description: z.string(),
  categories: z.string().array(),
  questions: z.array(z.unknown()).transform((items) =>
    items.filter((item): item is IQuestion => {
      return ZQuestion.safeParse(item).success === true;
    }),
  ),
  estimatedTimeToComplete: z.number(),
});

export type IQuestionnaire = z.infer<typeof ZQuestionnaire>;

export const ZQuestionnaireResponse = z.object({ result: ZQuestionnaire });

export const isValidQuestionnaireId = (questionnaireId: string) =>
  questionnaireId === 'lifestyle' || questionnaireId === 'asthma';

export const getQuestionnaire = (
  questionnaireId: string,
  patientId: string,
  token: string,
) =>
  axios
    .get(
      `${BASE_URL}/patients/${patientId}/questionnaires/${questionnaireId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((response) => response.data as unknown);
