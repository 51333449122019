const onClick = () => {
  throw new Error('😈 oops');
};

const SentryTestErrorComponent = () => {
  return (
    <button type="button" onClick={onClick}>
      Oops
    </button>
  );
};

export default SentryTestErrorComponent;
