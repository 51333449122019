import QuestionnaireCTA from './QuestionnaireCta';

type Props = {
  disable?: boolean;
};

const NextButton = ({ disable }: Props) => {
  return (
    <QuestionnaireCTA type="submit" disabled={disable}>
      Next
    </QuestionnaireCTA>
  );
};

export default NextButton;
