import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { keyBy } from 'lodash/fp';

import Loading from 'app/components/Loading';
import QuestionnaireFetchError from './QuestionnaireFetchError';

import { useQuestionnaireId } from '../hooks/routing';
import useGetQuestionnaire from '../hooks/useGetQuestionnaire';
import {
  questionnaireAtomFamily,
  IQuestionnaireState,
  IQuestionWithState,
} from '../state/questionnaire';
import { IQuestionnaire } from '../api';

const keyById = keyBy<IQuestionWithState>('_id');

const transformQuestionnaireData = (
  data: IQuestionnaire,
): IQuestionnaireState => {
  const questionsWithExtraData = data.questions.map((question, index) => ({
    ...question,
    questionIndex: index,
  }));

  return {
    ...data,
    totalQuestionCount: data.questions.length,
    questions: questionsWithExtraData,
    questionsById: { ...keyById(questionsWithExtraData) },
  };
};

const QuestionnaireInitialiser: FC = () => {
  const questionnaireId = useQuestionnaireId();
  const query = useGetQuestionnaire(questionnaireId);
  const [questionnaireState, setQuestionnaireState] = useRecoilState(
    questionnaireAtomFamily(questionnaireId),
  );

  useEffect(() => {
    if (query.data && query.data._id) {
      const questionnaireTransformedForState = transformQuestionnaireData(
        query.data,
      );

      setQuestionnaireState(questionnaireTransformedForState);
    }
  }, [query.data, setQuestionnaireState]);

  if (questionnaireState) {
    return <Navigate to="start" />;
  }

  if (query.isError) {
    return <QuestionnaireFetchError />;
  }

  return <Loading />;
};

export default QuestionnaireInitialiser;
