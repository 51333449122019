import { Box, Button, Divider, Typography } from '@mui/material';

interface CellTimePriorityProps {
  text: string;
  time: string;
  required?: boolean;
  readingNumber?: number;
  onClickStart: () => void;
  numberOfSetsRequested?: number;
}

interface RequiredStatusProps {
  required: boolean;
}
const RequiredStatus: React.FC<RequiredStatusProps> = ({ required }) => {
  if (required) {
    return (
      <Typography color="colors.red" variant="body2">
        Required
      </Typography>
    );
  }
  return <Typography variant="body2">Optional</Typography>;
};

const CellTimePriority: React.FC<CellTimePriorityProps> = ({
  required = false,
  text,
  time,
  readingNumber,
  onClickStart,
  numberOfSetsRequested,
}) => {
  return (
    <>
      <Box paddingY="16px">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <RequiredStatus required={required} />

          <Typography variant="body2" color="colors.greyDark">
            {time} min
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" component="h2">
            {text}
          </Typography>

          <Button
            variant="text"
            onClick={onClickStart}
            sx={{
              marginRight: '-16px',
            }}
          >
            Start
          </Button>
        </Box>

        {numberOfSetsRequested &&
          readingNumber &&
          numberOfSetsRequested - readingNumber > 0 && (
            <Typography>
              {numberOfSetsRequested - readingNumber === 1
                ? 'You need to record 1 more blood pressure reading'
                : `You need to record ${numberOfSetsRequested - readingNumber} more blood pressure readings`}
            </Typography>
          )}
      </Box>

      <Divider sx={{ marginX: { xs: '-16px', sm: '0px' } }} />
    </>
  );
};

export default CellTimePriority;
