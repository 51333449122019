import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import * as Avo from 'analytics/Avo';

import Header from 'app/components/Header';
import PageLayout from 'app/components/PageLayout';
import { linkToHomepage } from 'app/routing/links';
import { logSentryError } from 'app/sentry';
import { patientDetailsSelector, suveraApiAuthTokenAtom } from 'app/state';
import { SUVERA_API } from 'app/suvera-api';

import LoginFormFields from './components/LoginFormFields';

const LoginScreen = (): ReactElement => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [dayOfBirth, setDayOfBirth] = useState<string>('');
  const [monthOfBirth, setMonthOfBirth] = useState<string>('');
  const [yearOfBirth, setYearOfBirth] = useState<string>('');
  const [userSignInStarted, setUserSignInStarted] = useState<boolean>(false);
  const [userSignInIsLoading, setUserSignInIsLoading] =
    useState<boolean>(false);
  const setAccessTokenInState = useSetRecoilState(suveraApiAuthTokenAtom);

  const [urlQuery] = useSearchParams();
  const redirectURL = urlQuery.get('redirect');
  const selectUserAsync = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(patientDetailsSelector),
  );

  useEffect(() => {
    if (
      (firstName || lastName || dayOfBirth || monthOfBirth || yearOfBirth) &&
      !userSignInStarted
    ) {
      Avo.userSignInStarted();
      setUserSignInStarted(true);
    }
  }, [
    firstName,
    lastName,
    dayOfBirth,
    monthOfBirth,
    yearOfBirth,
    userSignInStarted,
  ]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setUserSignInIsLoading(true);

    let monthToSubmit = monthOfBirth;
    let dayToSubmit = dayOfBirth;
    let yearToSubmit = yearOfBirth;
    if (
      Number.parseInt(monthOfBirth, 10) < 10 &&
      monthOfBirth.charAt(0) !== '0'
    ) {
      monthToSubmit = `0${monthOfBirth}`;
    }
    if (Number.parseInt(dayOfBirth, 10) < 10 && dayOfBirth.charAt(0) !== '0') {
      dayToSubmit = `0${dayOfBirth}`;
    }
    if (yearOfBirth.length === 2) {
      yearToSubmit = `19${yearOfBirth}`;
    }
    await SUVERA_API.authenticatePatient(
      'dob',
      firstName[0].toUpperCase() + firstName.slice(1).toLowerCase(),
      lastName[0].toUpperCase() + lastName.slice(1).toLowerCase(),
      `${yearToSubmit}-${monthToSubmit}-${dayToSubmit}`,
    )
      .then(async (response) => {
        if (response) {
          setAccessTokenInState(response);
          const patientDetails = await selectUserAsync();
          if (patientDetails) {
            Avo.userIdentified({ userId_: patientDetails._id });
            Avo.userSignInCompleted();

            navigate(redirectURL ?? linkToHomepage());
            setUserSignInIsLoading(false);
          }
        } else {
          setUserSignInIsLoading(false);
          Avo.userSignInFailed({ reason: 'not_found' });
          // eslint-disable-next-line no-alert
          alert(
            'No records found. Please check the spelling and date of birth of your credentials',
          );
        }
      })
      .catch((error) => {
        setUserSignInIsLoading(false);
        Avo.userSignInFailed({ reason: 'unknown' });
        if (error instanceof Error) {
          logSentryError(error);
        } else {
          throw error;
        }
      });
  };

  const userSignInIsDisabled =
    !firstName || !lastName || !dayOfBirth || !yearOfBirth || !monthOfBirth;

  return (
    <PageLayout>
      {{
        header: <Header />,
        body: (
          <Stack spacing="48px" component="form" onSubmit={handleSubmit}>
            <LoginFormFields
              setFirstName={(value: string) => setFirstName(value)}
              setLastName={(value: string) => setLastName(value)}
              setDayOfBirth={(value: string) => {
                setDayOfBirth(value);
              }}
              setMonthOfBirth={(value: string) => {
                setMonthOfBirth(value);
              }}
              setYearOfBirth={(value: string) => {
                setYearOfBirth(value);
              }}
              firstName={firstName}
              lastName={lastName}
              dayOfBirth={dayOfBirth}
              monthOfBirth={monthOfBirth}
              yearOfBirth={yearOfBirth}
            />

            <LoadingButton
              disabled={userSignInIsDisabled}
              loading={userSignInIsLoading}
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              Login
            </LoadingButton>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default LoginScreen;
