import { Button, Stack, TextField, Typography } from '@mui/material';
import { FormEventHandler, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SimpleHeaderBar from 'app/components/SimpleHeaderBar';
import { logSentryError } from 'app/sentry';
import { SUVERA_API } from 'app/suvera-api';

import Loading from 'app/components/Loading';
import PageLayout from 'app/components/PageLayout';
import useAccessToken from 'app/hooks/useAccessToken';
import usePatientId from 'app/hooks/usePatientId';
import { linkToHomepage } from 'app/routing/links';

import { linkToSupport } from '../Support/links';
import { linkToSendMessageSubmitted } from './links';

const SendMessage = (): ReactElement => {
  const [message, setMessage] = useState<string>('');
  const navigate = useNavigate();

  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  const patientId = usePatientId();
  const token = useAccessToken();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setShouldValidate(true);
    if (!message) return;

    setLoading(true);

    SUVERA_API.sendMessage(patientId, message, token)
      .then((sendMessageResponse) => {
        if (!sendMessageResponse)
          throw new Error('Attempt to send message to care team failed');
        navigate(linkToSendMessageSubmitted());
      })
      .then(() => {
        setTimeout(() => {
          window.location.assign(linkToHomepage());
        }, 6000);
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          logSentryError(error);
        } else {
          throw error;
        }
      })
      .finally(() => setLoading(false));
  };

  return loading ? (
    <Loading />
  ) : (
    <PageLayout>
      {{
        header: (
          <SimpleHeaderBar
            buttonText="Back"
            buttonClicked={() => {
              navigate(linkToSupport());
            }}
          />
        ),

        body: (
          <Stack paddingTop="74px">
            <Stack rowGap="4px" paddingBottom="16px">
              <Typography component="h1" variant="h2">
                Send us a message
              </Typography>
              <Typography variant="body2">
                If you&apos;re having an emergency or have an urgent concern,
                call 111 or go to A&E
              </Typography>
            </Stack>

            <Stack component="form" onSubmit={onSubmit} rowGap="64px">
              <TextField
                multiline
                label="Enter your message here"
                variant="outlined"
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                error={shouldValidate === true && message === ''}
                helperText={
                  shouldValidate === true && message === ''
                    ? 'Please enter a message'
                    : ''
                }
              />

              <Button variant="contained" fullWidth type="submit" size="large">
                Submit
              </Button>
            </Stack>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default SendMessage;
