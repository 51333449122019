import ErrorScreen from 'app/components/ErrorScreen';
import PageLayout from 'app/components/PageLayout';
import QuestionnaireHeader from 'pages/Questionnaire/components/QuestionnaireHeader';
import { useQuestionnaireId } from 'pages/Questionnaire/hooks/routing';
import { linkToCommonQuestionnaireStep } from 'pages/Questionnaire/links';

import ECommonStep from '../../types/ECommonStep';

const QuestionnaireSubmissionError = () => {
  const questionnaireId = useQuestionnaireId();

  return (
    <PageLayout>
      {{
        header: <QuestionnaireHeader stepId={ECommonStep.ERROR} />,
        body: (
          <ErrorScreen
            description="Please go back and try again"
            buttonText="Go back"
            buttonUrl={linkToCommonQuestionnaireStep(
              questionnaireId,
              ECommonStep.REVIEW,
            )}
          />
        ),
      }}
    </PageLayout>
  );
};

export default QuestionnaireSubmissionError;
