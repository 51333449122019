import getStepsFromQuestions from './getStepsFromQuestions';

import { IQuestionnaire } from '../api';
import ECommonStep from '../types/ECommonStep';

export default function getQuestionnaireProgress(
  stepId: string,
  questionnaire: IQuestionnaire,
) {
  const steps = getStepsFromQuestions(questionnaire.questions);
  const progressSteps = steps.filter(
    (step) =>
      !(
        [
          ECommonStep.START,
          ECommonStep.DISCLAIMER,
          ECommonStep.COMPLETED,
        ] as string[]
      ).includes(step._id),
  );
  const currentStepIndex = progressSteps.findIndex(
    (step) => step._id === stepId,
  );

  return {
    currentStepCount: currentStepIndex + 1,
    totalStepCount: progressSteps.length,
  };
}
