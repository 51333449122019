const CheckIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#28324D" fillOpacity="0.3" />
    <path
      d="M9.43958 19.5818C8.09996 18.0271 10.1256 15.6497 11.465 17.2045L15.5765 21.9556L24.5253 11.5113C25.8649 9.94778 27.9015 12.3248 26.5619 13.8884L16.5998 25.509C16.0404 26.1622 15.1331 26.1641 14.5716 25.5123L9.43958 19.5818Z"
      fill="#42495E"
    />
  </svg>
);

export default CheckIcon;
