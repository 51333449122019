import { ArrowBackIosNew, Help } from '@mui/icons-material';
import { Box, Button, Divider, useMediaQuery } from '@mui/material';
import { useState } from 'react';

import Avo from 'analytics/Avo';
import { linkToHelp } from 'app/routing/links';

import { useRecoilState } from 'recoil';
import CancelModal from './CancelModal';
import { useQuestionnaireId } from '../hooks/routing';
import { useQuestionProgress } from '../hooks/useQuestionProgress';
import { questionnaireAnswersAtom } from '../state';

type Props = { stepId: string };

const handleHelp = () => {
  window.open(linkToHelp(), '_blank')?.focus();
  Avo.helpButtonClicked({ location: 'questionnaire_lifestyle' });
};

const QuestionnaireHeader = ({ stepId }: Props) => {
  const questionnaireId = useQuestionnaireId();
  const { questionIndex, totalQuestionCount } = useQuestionProgress();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [answers, setAnswers] = useRecoilState(questionnaireAnswersAtom);

  const [open, setOpen] = useState(false);
  const startIconIsShown = useMediaQuery('(min-width: 900px)');

  const handleOpen = () => {
    Avo.questionnaireCancelModalOpened({
      questionnaireType: questionnaireId,
      stepId,
      questionIndex,
      totalQuestionCount,
    });
    setOpen(true);
  };

  const handleClose = () => {
    Avo.questionnaireCancelModalClosed({
      questionnaireType: questionnaireId,
      stepId,
      questionIndex,
      totalQuestionCount,
    });
    setOpen(false);
  };

  const handleCancel = () => {
    Avo.questionnaireCancelled({
      questionnaireType: questionnaireId,
      stepId,
      questionIndex,
      totalQuestionCount,
    });
    setAnswers({});
  };

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingX={{ md: '56px', sm: '16px' }}
          paddingY="12px"
          marginX={2}
        >
          <Button
            variant="text"
            sx={{
              paddingY: '4px',
              paddingX: '12px',
            }}
            onClick={handleOpen}
            startIcon={startIconIsShown && <ArrowBackIosNew />}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            role="button"
            sx={{
              height: 'none',
              width: 'none',
              padding: '4px 12px',
            }}
            disableRipple
            onClick={handleHelp}
            startIcon={<Help />}
          >
            Help
          </Button>
        </Box>

        <Divider />
      </Box>

      <CancelModal
        open={open}
        handleClose={handleClose}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default QuestionnaireHeader;
