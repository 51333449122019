import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

import Frame_28899 from './Frame_28899.png';

interface SentConfirmationProps {
  title: string;
  subTitle?: string;
}

const SentConfirmationContainer = styled('div')(() => ({
  textAlign: 'center',
  padding: 30,
}));

const Title = styled('p')(({ theme }) => ({
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
  fontSize: '20px',
}));

const SubTitle = styled('p')(({ theme }) => ({
  fontWeight: 400,
  fontFamily: theme.typography.fontFamily,
  fontSize: '16px',
  lineHeight: '20px',
}));

const Row = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 10,
}));

const SentConfirmation = (props: SentConfirmationProps): ReactElement => {
  const { title, subTitle } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#E8FCEF',
      }}
    >
      <SentConfirmationContainer>
        <img src={Frame_28899} alt="sent_icon" />
        <br />
        <Row>
          <Title>{title}</Title>
          <Title style={{ marginLeft: 10 }}>✅</Title>
        </Row>
        {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
      </SentConfirmationContainer>
    </div>
  );
};

export default SentConfirmation;
