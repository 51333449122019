/* eslint-disable unicorn/filename-case */
import {
  IAppointment,
  IClinicalReading,
  IInteraction,
  IInteractionType,
  IPatient,
  IPractice,
  ISuverian,
  NoIDNestedInteraction,
} from '@suvera/core-types';

const serialize = (obj: Record<string, string | number | boolean>) => {
  const str = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const p in obj)
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  return str.join('&');
};

const automationSuverianStaging = '621596f949826692cda651db';
const automationSuverianProd = '6215bb64dfd944c0a2dd53f0';

export const BASE_URL = process.env.REACT_APP_SUVERA_API_BASE_URL as string;

const getAutomationSuverian = () =>
  process.env.REACT_APP_SST_STAGE === 'production'
    ? automationSuverianProd
    : automationSuverianStaging;

enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}

// N.B. Ideally the types here should be removed completely and
// replaces with the ones from the core-types package
export interface IPatientPopulated extends Omit<IPatient, 'practice'> {
  practice: { _id: IPractice['_id']; name: IPractice['name'] };
  active_tasks: ITask[];
}

export type ESubmitBpTaskType = 'submit_bp' | 'submit_triage_bp';
export type ETaskType = ESubmitBpTaskType | 'submit_form';
interface TaskGeneric<T = ETaskType> {
  _id: string;
  datetime: Date;
  start: Date;
  note: string;
  task_type_id: T;
}

export type ISubmitBPTask = TaskGeneric<'submit_bp'> & {
  numberOfSetsRequested: number;
};

export type ISubmitTriageBpTask = TaskGeneric<'submit_triage_bp'> & {
  numberOfSetsRequested: number;
};

export type IAnySubmitBpTask = ISubmitTriageBpTask | ISubmitBPTask;

export type ISubmitFormTask = TaskGeneric<'submit_form'> & {
  formType: string;
};

export type ITask = IAnySubmitBpTask | ISubmitFormTask;

export interface ITokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  token_type: string;
}

export interface IInteractionPopulated
  extends Omit<
    IInteraction,
    'interaction_type' | 'suverian' | 'clinical_readings'
  > {
  interaction_type: IInteractionType;
  suverian: ISuverian;
  clinical_readings: IClinicalReading[][];
}

export interface IPatientAuthentication {
  sub: string; // this is the ID
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  iat: number;
  exp: number;
}

interface APICallBody {
  type: 'json' | 'x-www-form-urlencoded';
  content: unknown;
}

function makeAPICall<T>(
  method: METHOD,
  path: string,
  token?: string | undefined,
  body?: APICallBody | undefined,
): Promise<T> {
  const authHeaders: HeadersInit = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  let reqParams: RequestInit = {};
  if ((method === METHOD.POST || method === METHOD.PATCH) && body) {
    switch (body.type) {
      case 'json': {
        reqParams = {
          headers: {
            'Content-Type': 'application/json',
            ...authHeaders,
          },
          body: JSON.stringify(body.content),
        };
        break;
      }
      case 'x-www-form-urlencoded': {
        reqParams = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...authHeaders,
          },
          body: serialize(
            body.content as Record<string, string | number | boolean>,
          ),
        };
        break;
      }
      default: {
        throw new Error('Unknown request body type');
      }
    }
  } else {
    reqParams = {
      headers: {
        ...authHeaders,
      },
    };
  }
  const req: RequestInit = {
    method,
    credentials: token === undefined ? undefined : 'include',
    ...reqParams,
  };
  return fetch(BASE_URL + path, req).then((response) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    response.json().then((json) => json.result as T),
  );
}

export const SUVERA_API = {
  getPatientAppointments: (
    patientID: string,
    token: string,
  ): Promise<IAppointment[]> =>
    makeAPICall(METHOD.GET, `/patients/${patientID}/appointments`, token),
  getPatientInteractions: (
    patientID: string,
    token: string,
  ): Promise<IInteractionPopulated[]> =>
    makeAPICall(METHOD.GET, `/patients/${patientID}/interactions`, token),
  authenticatePatient: (
    grant_type: string,
    first_name: string,
    last_name: string,
    dob: string,
  ): Promise<ITokenResponse> =>
    makeAPICall(METHOD.POST, '/oauth/patients/token', undefined, {
      type: 'x-www-form-urlencoded',
      content: {
        grant_type,
        first_name,
        last_name,
        dob,
      },
    }),
  getPatientMe: (token: string): Promise<IPatientPopulated> =>
    makeAPICall(METHOD.GET, '/patients/me', token),
  optOut: (
    patientId: string,
    optOutReason: string,
    token: string,
  ): Promise<ITask> => {
    const task = {
      task_type: 'opt_out_discussion',
      start: new Date(),
      datetime: new Date(),
      note: optOutReason,
      patient: patientId,
      suverian: getAutomationSuverian(),
      assignment: undefined,
      completion: undefined,
    };
    return makeAPICall(METHOD.POST, `/patients/${patientId}/tasks`, token, {
      type: 'json',
      content: task,
    });
  },
  sendMessage: (
    patientId: string,
    message: string,
    token: string,
  ): Promise<ITask> => {
    const task = {
      task_type: 'respond_to_message',
      start: new Date(),
      datetime: new Date(),
      note: message,
      patient: patientId,
      suverian: getAutomationSuverian(),
      assignment: undefined,
      completion: undefined,
    };
    return makeAPICall(METHOD.POST, `/patients/${patientId}/tasks`, token, {
      type: 'json',
      content: task,
    });
  },
  createInteraction: (
    interaction: NoIDNestedInteraction,
    token: string,
  ): Promise<IInteractionPopulated> =>
    makeAPICall(
      METHOD.POST,
      `/patients/${interaction.patient}/interactions`,
      token,
      {
        type: 'json',
        content: interaction,
      },
    ),
};
