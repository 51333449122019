import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type Props = {
  value: string;
  onChange: (value: string) => void;
  metricUnit: string;
  imperialUnit: string;
};

const toggleButtonStyles = {
  textTransform: 'uppercase',
  borderRadius: '20px',
  paddingY: '8px',
  backgroundColor: 'colors.greyLightest',
  borderColor: 'colors.greyLight',

  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    borderColor: 'colors.black',
    backgroundColor: 'colors.black',
  },
};

const UnitToggle = ({ value, onChange, metricUnit, imperialUnit }: Props) => {
  const handleToggleUnit = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleToggleUnit}
      aria-label="Change the unit of measurement"
      fullWidth
      style={{ marginTop: '16px' }}
    >
      <ToggleButton
        sx={toggleButtonStyles}
        value="metric"
        aria-label="Metric unit"
      >
        {metricUnit}
      </ToggleButton>
      <ToggleButton
        sx={toggleButtonStyles}
        value="imperial"
        aria-label="Imperial unit"
      >
        {imperialUnit}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default UnitToggle;
