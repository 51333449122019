import {
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';

import { Field, FieldProps } from 'formik';

export default function WeightInputInPounds() {
  return (
    <>
      <Field name="st">
        {(fieldProps: FieldProps<number>) => (
          <TextField
            variant="outlined"
            type="number"
            placeholder="0"
            value={fieldProps.field.value}
            onChange={(event) =>
              fieldProps.form.setFieldValue('st', event.target.value)
            }
            inputProps={{
              'aria-label': 'Weight in stones',
              inputMode: 'numeric',
            }}
            error={!!fieldProps.meta.error}
            InputProps={{
              endAdornment: <InputAdornment position="end">ST</InputAdornment>,
            }}
          />
        )}
      </Field>
      <Field name="lb">
        {(fieldProps: FieldProps<number>) => {
          return (
            <Stack>
              <TextField
                variant="outlined"
                type="number"
                placeholder="0"
                value={fieldProps.field.value}
                onChange={(event) =>
                  fieldProps.form.setFieldValue('lb', event.target.value)
                }
                onBlur={(event) => {
                  fieldProps.field.onBlur(event);
                }}
                error={!!fieldProps.meta.error}
                inputProps={{
                  'aria-label': 'Weight in pounds',
                  inputMode: 'numeric',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">LB</InputAdornment>
                  ),
                }}
              />

              <FormHelperText error sx={{ paddingX: '14px' }}>
                {fieldProps.meta.error || ' '}
              </FormHelperText>
            </Stack>
          );
        }}
      </Field>
    </>
  );
}
