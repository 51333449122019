import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Avo from 'analytics/Avo';
import ErrorScreen from 'app/components/ErrorScreen';
import PageLayout from 'app/components/PageLayout';
import { linkToHomepage } from 'app/routing/links';
import { logSentryError } from 'app/sentry';

const PageNotFound: FC = () => {
  const location = useLocation();

  useEffect(() => {
    Avo.pageViewed({
      customDestinationPageName_: 'not_found',
    });

    const pageNotFoundError = new Error(
      `Page not found - ${location.pathname}`,
    );
    logSentryError(pageNotFoundError);
  }, [location.pathname]);

  return (
    <PageLayout>
      {{
        header: <Box />,
        body: (
          <ErrorScreen
            title="Oops! Page not found"
            description="Please refresh the page or navigate to homepage"
            buttonText="Back to homepage"
            buttonOnClick={() => window.location.assign(linkToHomepage())}
          />
        ),
      }}
    </PageLayout>
  );
};

export default PageNotFound;
