import { Button, Stack, Typography } from '@mui/material';

type Props = {
  text: string;
  description?: string;
  isSelected: boolean;
  handleClick: () => void;
};

const SelectableCard = ({
  text,
  description,
  isSelected,
  handleClick,
}: Props) => {
  const selectedStyle = isSelected
    ? {
        backgroundColor: 'colors.blueLight',
        // TODO: replace with colors.blue once migrated to a new theme
        borderColor: '#246CCC',
      }
    : {
        backgroundColor: 'colors.greyLightest',
        borderColor: 'colors.greyLight',
      };

  return (
    <Button
      onClick={handleClick}
      role="radio"
      aria-checked={isSelected}
      data-value="True"
      sx={{
        ...selectedStyle,
        color: 'colors.black',
        borderStyle: 'solid',
        borderRadius: '8px',
        borderWidth: '2px',
        justifyContent: 'start',
        paddingX: '16px',
        paddingY: '12px',
        '&:hover': {
          backgroundColor: 'colors.blueLight',
        },
      }}
    >
      <Stack
        justifyContent="space-between"
        alignItems="start"
        width="100%"
        textAlign="left"
        spacing="4px"
      >
        <Typography variant="body2">{text}</Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </Stack>
    </Button>
  );
};

export default SelectableCard;
