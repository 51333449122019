import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import PageLayout from 'app/components/PageLayout';

import { linkToHelpBpTutorial } from '../HelpSectionBpTutorial/links';
import pillCheckingPhoneSrc from './images/checking_phone.png';

const HelpLandingPage = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      {{
        header: <Box height="50px" />,
        body: (
          <Stack spacing="16px">
            <Typography variant="h2" component="h1" textAlign="center">
              Need help?
            </Typography>

            <Box
              component="img"
              sx={{
                width: '100px',
                height: '180px',
                objectFit: 'contain',
                content: `url(${pillCheckingPhoneSrc})`,
                alignSelf: 'center',
                paddingY: '32px',
                boxSizing: 'content-box',
              }}
              alt="Illustration of a pill mascot smiling and holding a tablet"
            />

            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => navigate(linkToHelpBpTutorial())}
            >
              Read blood pressure tutorial
            </Button>

            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                window.open('https://www.suvera.co.uk/support', '_blank');
              }}
            >
              Support and FAQ page
            </Button>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default HelpLandingPage;
