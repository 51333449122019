import imgStep1 from './assets/step-1.png';
import imgStep2 from './assets/step-2.png';
import imgStep3 from './assets/step-3.png';
import imgStep4 from './assets/step-4.png';
import { linkToHelpBpTutorialStep } from './links';

export type StepConfig = {
  title: string;
  body: string;
  getNextStep: () => string;
  getPreviousStep: () => string;
  position: number;
  image: string;
  altText: string;
  totalSteps: number;
};

export type StepId = 'one' | 'two' | 'three' | 'four';

const TOTAL_STEPS = 4;

export const TUTORIAL_STEPS_CONFIG: Record<StepId, StepConfig> = {
  one: {
    title: 'Get in position',
    body: `Sit with your back against the chair with both feet flat on the
        ground. Roll your sleeve up and rest your arm on a flat surface at
        heart level. Make sure to relax and wait 5 minutes before taking your
        first reading.`,
    getNextStep: () => linkToHelpBpTutorialStep('2'),
    getPreviousStep: () => '',
    position: 0,
    image: imgStep1,
    altText: 'Person sitting relaxed with arm resting on the table',
    totalSteps: TOTAL_STEPS,
  },
  two: {
    title: 'Put the cuff on',
    body: 'Put your arm through the loose cuff with the tube running down the center of your inner arm, pointed down towards your wrist. Once it’s in place, tighten the cuff. You should be able to only fit two fingers beneath it.',
    getNextStep: () => linkToHelpBpTutorialStep('3'),
    getPreviousStep: () => linkToHelpBpTutorialStep('1'),
    position: 1,
    image: imgStep2,
    altText: 'Person wrapping a blood pressure monitor cuff around their arm',
    totalSteps: TOTAL_STEPS,
  },
  three: {
    title: 'Take your blood pressure',
    body: 'To start, simply press the ‘Start/Stop’ button on your blood pressure monitor. Stay still and relaxed, and don’t talk. Your cuff will feel tight around your arm as it inflates, but if it gets too uncomfortable, press the button again to stop the monitor.',
    getNextStep: () => linkToHelpBpTutorialStep('4'),
    getPreviousStep: () => linkToHelpBpTutorialStep('2'),
    position: 2,
    image: imgStep3,
    altText:
      'Person pressing the start/stop button on a blood pressure monitor',
    totalSteps: TOTAL_STEPS,
  },
  four: {
    title: 'Record your readings',
    body: `As you take your readings, record them onto the form. Enter the larger number first, followed by the smaller number.
        ‍<br>
        <br>
        And that’s it — you’re all done!`,
    getNextStep: () => '',
    getPreviousStep: () => linkToHelpBpTutorialStep('3'),
    position: 3,
    image: imgStep4,
    altText: 'Person recording readings with a phone',
    totalSteps: TOTAL_STEPS,
  },
};
