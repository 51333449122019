import { FC, ReactElement, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { linkToHomepage } from 'app/routing/links';

import { isValidQuestionnaireId } from './api';
import { useQuestionnaireId } from './hooks/routing';
import { linkToQuestionnaire } from './links';
import QuestionnaireCompleted from './pages/QuestionnaireCompleted';
import QuestionnaireDisclaimer from './pages/QuestionnaireDisclaimer';
import QuestionnaireInitialiser from './pages/QuestionnaireInitialiser';
import QuestionnaireQuestion from './pages/QuestionnaireQuestion';
import QuestionnaireReview from './pages/QuestionnaireReview';
import QuestionnaireStart from './pages/QuestionnaireStart';
import QuestionnaireSubmissionError from './pages/QuestionnaireSubmissionError';
import { questionnaireAtomFamily } from './state/questionnaire';

const WithValidatedQuestionnaireState: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const questionnaireId = useQuestionnaireId();

  const navigate = useNavigate();
  const questionnaireState = useRecoilValue(
    questionnaireAtomFamily(questionnaireId),
  );

  useEffect(() => {
    if (!questionnaireState || !questionnaireState._id) {
      navigate(linkToQuestionnaire(questionnaireId));
    }
  }, [navigate, questionnaireState, questionnaireId]);
  if (questionnaireState) return children;
  return null;
};

const useRedirectOnInvalidQuestionnaireId = () => {
  const questionnaireId = useQuestionnaireId();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isValidQuestionnaireId(questionnaireId)) {
      navigate(linkToHomepage());
    }
  }, [navigate, questionnaireId]);
};

const useScrollToTopOnMount = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
};

const Questionnaire = () => {
  useRedirectOnInvalidQuestionnaireId();
  useScrollToTopOnMount();

  return (
    <Routes>
      <Route
        path="question/:questionId"
        element={
          <WithValidatedQuestionnaireState>
            <QuestionnaireQuestion />
          </WithValidatedQuestionnaireState>
        }
      />
      <Route
        path="start"
        element={
          <WithValidatedQuestionnaireState>
            <QuestionnaireStart />
          </WithValidatedQuestionnaireState>
        }
      />
      <Route
        path="disclaimer"
        element={
          <WithValidatedQuestionnaireState>
            <QuestionnaireDisclaimer />
          </WithValidatedQuestionnaireState>
        }
      />
      <Route
        path="review"
        element={
          <WithValidatedQuestionnaireState>
            <QuestionnaireReview />
          </WithValidatedQuestionnaireState>
        }
      />
      <Route path="completed" element={<QuestionnaireCompleted />} />
      <Route path="error" element={<QuestionnaireSubmissionError />} />
      <Route path="" element={<QuestionnaireInitialiser />} />
      <Route path="*" element={<Navigate to="" />} />,
    </Routes>
  );
};

export default Questionnaire;
