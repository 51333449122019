import { Button } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'submit' | 'button';
};

const QuestionnaireCTA = ({
  children,
  type = 'button',
  disabled,
  ...buttonProps
}: Props) => {
  return (
    <Button
      variant="contained"
      role="button"
      sx={{
        height: '48px',
        flex: 1,
        paddingX: '12px',
        paddingY: '16px',
      }}
      disabled={disabled}
      disableRipple
      type={type}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default QuestionnaireCTA;
