import { Box, Divider, Stack, Typography } from '@mui/material';
import { isArray } from 'lodash';

import { IQuestion } from 'pages/Questionnaire/api';
import {
  EQuestionnaireBaseAnswer,
  IQuestionnaireCalculatorAnswer,
} from 'pages/Questionnaire/state';

type Props = {
  question: IQuestion;
  answer?: EQuestionnaireBaseAnswer;
};

const heightToDisplay = (
  answer: IQuestionnaireCalculatorAnswer | undefined,
) => {
  if (!answer) return '-';

  if (!answer.value) return '-';

  const heightUnit = answer.unit;

  if (heightUnit === 'metric') {
    return `${answer.value}cm`;
  }

  return `${answer.valueInFeet}'${answer.valueInInches}"`;
};

const weightToDisplay = (
  answer: IQuestionnaireCalculatorAnswer | undefined,
) => {
  if (!answer) return '-';

  if (!answer.value) return '-';

  const weightUnit = answer.unit;

  if (weightUnit === 'metric') {
    return `${answer.value}kg`;
  }

  return `${answer.valueInStones}st ${answer.valueInPounds}lb`;
};

const ReviewQuestionRow = ({ question, answer }: Props) => {
  let displayedAnswer = '-';

  if (question.questionType === 'single_select') {
    const chosenOption = question.options.find(({ value }) => value === answer);
    displayedAnswer = chosenOption ? chosenOption.text : '-';
  }

  if (question.questionType === 'multi_select' && isArray(answer)) {
    const chosenOption = question.options.filter(({ value }) =>
      answer.includes(value),
    );
    displayedAnswer =
      chosenOption.length > 0
        ? chosenOption.map((o) => o.text).join(', ')
        : '-';
  }

  if (question.questionType === 'height_calculator') {
    displayedAnswer = heightToDisplay(answer as IQuestionnaireCalculatorAnswer);
  }

  if (question.questionType === 'weight_calculator') {
    displayedAnswer = weightToDisplay(answer as IQuestionnaireCalculatorAnswer);
  }

  return (
    <>
      <Stack spacing="12px" data-testid={question._id}>
        <Typography variant="body2">{question.questionText}</Typography>
        <Typography>{displayedAnswer}</Typography>
      </Stack>

      <Box>
        <Divider sx={{ marginX: { xs: '-16px', sm: '0px' } }} />
      </Box>
    </>
  );
};

export default ReviewQuestionRow;
