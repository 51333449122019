/* eslint-disable @typescript-eslint/no-floating-promises */
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Inspector from 'avo-inspector';

import * as Avo from './Avo';
import { getAvoCodegenEnv, getAvoInspectorEnv } from './utils';

const inspector = new Inspector.AvoInspector({
  apiKey: Avo.avoInspectorApiKey,
  env: getAvoInspectorEnv(),
  version: '1.0.0',
  appName: 'Patient Web App',
});

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_AVO_SEGMENT_KEY || '',
});

const customDestination = {
  make() {
    AnalyticsBrowser.load({
      writeKey: process.env.REACT_APP_AVO_SEGMENT_KEY || '',
    });
  },
  logEvent(eventName: string, eventProperties: object) {
    analytics.track(eventName, eventProperties);
  },
  setUserProperties(userId: string, userProperties: object) {
    analytics.identify(userId, userProperties);
  },
  identify(userId: string) {
    analytics.identify(userId);
  },
  unidentify() {
    analytics.identify(null);
  },
  logPage(pageName: string, eventProperties: object) {
    analytics.page({ ...eventProperties, pageName });
  },
};

const initialiseAvo = () => {
  Avo.initAvo(
    {
      env: getAvoCodegenEnv(),
      inspector,
      noop: process.env.REACT_APP_AVO_ENABLE !== 'true',
      webDebugger: false,
    },
    {},
    customDestination,
  );
};

export default initialiseAvo;
