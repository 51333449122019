import { useRecoilValue } from 'recoil';
import { useQuestionId, useQuestionnaireId } from './routing';
import { selectQuestionProgressById } from '../state/questionnaire';

export const useQuestionProgress = () => {
  const questionnaireId = useQuestionnaireId();
  const questionId = useQuestionId();

  const questionProgress = useRecoilValue(
    selectQuestionProgressById({ questionnaireId, questionId }),
  );

  if (!questionId)
    return { questionIndex: undefined, totalQuestionCount: undefined };

  return questionProgress;
};
