export const convertToStonesAndPounds = (valueInKilograms: number) => {
  const unroundedStones = valueInKilograms * 0.157_47;

  const stones = Math.floor(unroundedStones);
  const pounds = Math.round((unroundedStones - stones) * 14);

  if (pounds === 14) return [stones + 1, 0];

  return [stones, pounds];
};
