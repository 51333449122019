import { useEffect } from 'react';

import Avo from 'analytics/Avo';
import ErrorScreen from 'app/components/ErrorScreen';
import PageLayout from 'app/components/PageLayout';
import QuestionnaireHeader from 'pages/Questionnaire/components/QuestionnaireHeader';
import { useQuestionnaireId } from 'pages/Questionnaire/hooks/routing';

import ECommonStep from '../../types/ECommonStep';

const QuestionnaireFetchError = () => {
  const questionnaireId = useQuestionnaireId();

  useEffect(() => {
    if (questionnaireId) {
      Avo.questionnaireFetchErrored({
        questionnaireType: questionnaireId,
      });
    }
  }, [questionnaireId]);

  return (
    <PageLayout>
      {{
        header: <QuestionnaireHeader stepId={ECommonStep.ERROR} />,
        body: <ErrorScreen />,
      }}
    </PageLayout>
  );
};

export default QuestionnaireFetchError;
