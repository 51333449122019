import { useParams } from 'react-router-dom';

export const useQuestionId = () => {
  const param = useParams().questionId || '';
  return param;
};

export const useQuestionnaireId = () => {
  const param = useParams().questionnaireId || '';
  return param;
};
