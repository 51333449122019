import { ArrowBackIosNew, Help } from '@mui/icons-material';
import { Box, Button, SxProps, Theme, useMediaQuery } from '@mui/material';
import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { setupNavStyle } from 'app/components/TopNavigation/index.styled';
import { linkToHomepage } from 'app/routing/links';
import { patientDetailsSelector } from 'app/state';

interface HeaderBarProps {
  helpClicked: MouseEventHandler<HTMLButtonElement> | undefined;
  cancelClicked?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const HeaderBar = (props: HeaderBarProps): ReactElement => {
  const { helpClicked, cancelClicked } = props;
  const navigate = useNavigate();
  const loggedInPatient = useRecoilValue(patientDetailsSelector);
  const startIconIsShown = useMediaQuery('(min-width: 900px)');

  const onCancelClicked = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (cancelClicked) {
        cancelClicked(e);
      } else if (loggedInPatient) {
        navigate(linkToHomepage());
      }
    },
    [cancelClicked, loggedInPatient, navigate],
  );

  return (
    <Box sx={setupNavStyle as SxProps<Theme>}>
      <Button
        variant="text"
        size="small"
        onClick={onCancelClicked}
        startIcon={startIconIsShown && <ArrowBackIosNew />}
      >
        Cancel
      </Button>

      <Button
        size="small"
        variant="contained"
        startIcon={<Help />}
        onClick={helpClicked}
      >
        I need support
      </Button>
    </Box>
  );
};

export default HeaderBar;
