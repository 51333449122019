import { useQuery } from 'react-query';
import useAccessToken from 'app/hooks/useAccessToken';
import usePatientId from 'app/hooks/usePatientId';
import { logSentryError } from 'app/sentry';

import {
  getQuestionnaire,
  IQuestionnaire,
  isValidQuestionnaireId,
  ZQuestionnaireResponse,
} from '../api';

const useGetQuestionnaire = (questionnaireId: string) => {
  const token = useAccessToken();
  const patientId = usePatientId();
  return useQuery<IQuestionnaire>({
    queryKey: ['questionnaire', questionnaireId, patientId, token],
    queryFn: async () => {
      const data = await getQuestionnaire(questionnaireId, patientId, token);
      return ZQuestionnaireResponse.parse(data).result;
    },
    retry: false,
    onError: (error) => {
      logSentryError(error as Error | string);
    },

    enabled: isValidQuestionnaireId(questionnaireId),
  });
};

export default useGetQuestionnaire;
