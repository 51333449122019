export const linkToQuestionnaire = (questionnaireId: string) =>
  `/questionnaire/${questionnaireId}`;

export const linkToCommonQuestionnaireStep = (
  questionnaireId: string,
  stepId: string,
) => `${linkToQuestionnaire(questionnaireId)}/${stepId}`;

export const linkToQuestionnaireQuestion = (
  questionnaireId: string,
  questionId: string,
) => `${linkToQuestionnaire(questionnaireId)}/question/${questionId}`;
