import { Box, CircularProgress, Typography } from '@mui/material';
import { ReactElement } from 'react';

const Loading = (): ReactElement => (
  <Box style={{ height: '100vh' }}>
    <Box sx={{ textAlign: 'center', marginTop: '40vh' }}>
      <Typography variant="body1">Loading...</Typography>
      <br />
      <CircularProgress />
    </Box>
  </Box>
);

export default Loading;
