import { Navigate, Route } from 'react-router-dom';

import LoginCheck from 'app/LoginCheck';
import SentConfirmation from 'app/components/SentConfirmation';
import { PATHS } from 'app/routing/paths';
import OptOut from 'pages/PostLogin/OptOut';

import { linkToOptOut } from './links';

const routes = [
  <Route
    path={PATHS.opt_out}
    element={<LoginCheck element={<OptOut />} />}
    key="optout"
  />,
  <Route
    path={PATHS.opt_out_submitted}
    element={
      <SentConfirmation
        title="Submitted"
        subTitle="Please note, this may take up to
two working days to come into effect."
      />
    }
    key="optout-step-submitted"
  />,
  <Route
    path="/optout/*"
    element={<LoginCheck element={<Navigate to={linkToOptOut()} />} />}
    key="optout-id"
  />,
];

export default routes;
