import { CircularProgress, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Avo from 'analytics/Avo';
import PageLayout from 'app/components/PageLayout';
import QuestionnaireHeader from 'pages/Questionnaire/components/QuestionnaireHeader';
import { useGetAdjacentStepUrls } from 'pages/Questionnaire/hooks/steps';
import { usePostQuestionnaireComplete } from 'pages/Questionnaire/hooks/usePostQuestionnaireComplete';
import { linkToCommonQuestionnaireStep } from 'pages/Questionnaire/links';

import ReviewQuestionRow from './components/ReviewQuestionRow';

import BackButton from '../../components/BackButton';
import QuestionnaireCTA from '../../components/QuestionnaireCta';
import QuestionnaireProgressBar from '../../components/QuestionnaireProgressBar';
import { useQuestionnaire } from '../../hooks/selectors';
import {
  EQuestionnaireBaseAnswer,
  postSubmissionPageTextAtom,
  questionnaireAnswersAtom,
} from '../../state';
import ECommonStep from '../../types/ECommonStep';
import { sanitiseAnswers } from './utils/sanitiseAnswers';

const QuestionnaireReview = () => {
  const { previousStepUrl, nextStepUrl } = useGetAdjacentStepUrls(
    ECommonStep.REVIEW,
  );

  const postQuestionnaireComplete = usePostQuestionnaireComplete();
  const navigate = useNavigate();
  const { questions, _id: questionnaireId } = useQuestionnaire();

  const answers = useRecoilValue(questionnaireAnswersAtom);
  const setPageText = useSetRecoilState(postSubmissionPageTextAtom);

  const handleClickBack = () => {
    navigate(previousStepUrl);
    Avo.questionnaireStepBackButtonClicked({
      questionnaireType: questionnaireId,
      stepId: ECommonStep.REVIEW,
      questionIndex: undefined,
      totalQuestionCount: undefined,
    });
  };

  const handleSubmit = () => {
    const sanitisedAnswers = sanitiseAnswers(answers);

    return postQuestionnaireComplete
      .mutateAsync({ questionnaireId, answers: sanitisedAnswers })
      .then((result) => {
        const postSubmissionPageText =
          result.data.result?.postSubmissionPageText;
        if (postSubmissionPageText !== undefined) {
          setPageText(postSubmissionPageText);
        }
        Avo.questionnaireCompleted({ questionnaireType: questionnaireId });
        navigate(nextStepUrl);
      })
      .catch(() => {
        Avo.questionnaireSubmissionErrored({
          questionnaireType: questionnaireId,
        });
        navigate(
          linkToCommonQuestionnaireStep(questionnaireId, ECommonStep.ERROR),
        );
      });
  };

  const handleClickSubmit = async () => {
    await handleSubmit();
    Avo.questionnaireStepNextButtonClicked({
      questionnaireType: questionnaireId,
      stepId: ECommonStep.REVIEW,
      questionIndex: undefined,
      totalQuestionCount: undefined,
    });
  };

  return (
    <PageLayout>
      {{
        header: <QuestionnaireHeader stepId={ECommonStep.REVIEW} />,
        body: (
          <Stack
            spacing={{ xs: '8px', sm: '12px' }}
            paddingBottom={{ xs: '16px', sm: '72px' }}
          >
            <QuestionnaireProgressBar stepId={ECommonStep.REVIEW} />

            <Stack spacing={{ xs: '24px', sm: '36px' }}>
              <Stack spacing={{ xs: '8px', sm: '24px' }}>
                <Typography component="h1" variant="subtitle1">
                  Check your answers
                </Typography>

                <Typography variant="body2">
                  Look over your answers before submitting. Once you submit you
                  won&apos;t be able to change them.
                </Typography>
              </Stack>

              <Stack spacing="16px">
                {questions.map((question) => (
                  <ReviewQuestionRow
                    key={question._id}
                    answer={
                      answers[question.metric] as EQuestionnaireBaseAnswer
                    }
                    question={question}
                  />
                ))}
              </Stack>
              <Stack direction="row" spacing="8px">
                <BackButton
                  loading={postQuestionnaireComplete.isLoading}
                  onClick={handleClickBack}
                />
                <QuestionnaireCTA onClick={handleClickSubmit}>
                  {postQuestionnaireComplete.isLoading ? (
                    <CircularProgress size="24px" aria-label="Loading..." />
                  ) : (
                    'Submit'
                  )}
                </QuestionnaireCTA>
              </Stack>
            </Stack>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default QuestionnaireReview;
