import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Checkbox from 'app/components/Checkbox';
import PageLayout from 'app/components/PageLayout';
import SelectableCardGroup from 'app/components/SelectableCardGroup';
import SimpleHeaderBar from 'app/components/SimpleHeaderBar';
import { logSentryError } from 'app/sentry';
import {
  patientDetailsSelector,
  suveraApiAccessTokenSelector,
} from 'app/state';
import { SUVERA_API } from 'app/suvera-api';

import { linkToOptOutSubmitted } from './links';

import { linkToSupport } from '../Support/links';

const OptOut = (): ReactElement => {
  const loggedInPatient = useRecoilValue(patientDetailsSelector);
  const accessToken = useRecoilValue(suveraApiAccessTokenSelector);
  const [stopMessages, setStopMessages] = useState<boolean>(false);
  const [optOutReason, setOptOutReason] = useState<string>('');

  const navigate = useNavigate();

  const handleOptOut = async () => {
    await SUVERA_API.optOut(
      loggedInPatient?._id as string,
      optOutReason,
      accessToken as string,
    )
      .then((optOutResponse) => {
        if (!optOutResponse) throw new Error('Attempt to opt out failed');
        navigate(linkToOptOutSubmitted());
      })
      .then(() => {
        setTimeout(() => {
          window.location.href = 'https://www.suvera.co.uk';
        }, 6000);
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          logSentryError(error);
        } else {
          throw error;
        }
      });
  };

  return (
    <PageLayout>
      {{
        header: (
          <SimpleHeaderBar
            buttonText="Back"
            buttonClicked={() => {
              navigate(linkToSupport());
            }}
          />
        ),
        body: (
          <Stack spacing="16px" paddingBottom={{ xs: '16px', sm: '72px' }}>
            <Typography variant="h2" component="h1">
              Opt out
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              Why don’t you want to use this service?
            </Typography>

            <Stack>
              <Box marginBottom="16px">
                <SelectableCardGroup
                  value={optOutReason}
                  options={[
                    {
                      label: 'I don’t want to use an online service',
                      value: 'not_interested_in_using_suvera',
                    },
                    {
                      label: 'I don’t have the right equipment',
                      value: 'no_equipment',
                    },
                    {
                      label: 'I don’t have a mobile phone',
                      value: 'no_mobile_number',
                    },
                    {
                      label: 'I have another medical condition',
                      value: 'another_medical_condition',
                    },
                    {
                      label: 'I’m being managed by my GP',
                      value: 'is_being_managed_by_gp',
                    },
                    {
                      label: 'I’m being managed by a specialist',
                      value: 'is_being_managed_by_a_specialist',
                    },
                    {
                      label: 'I’m unhappy with the care I’ve received',
                      value: 'unhappy_with_suvera',
                    },
                    {
                      label: 'I don’t speak English',
                      value: 'language_barrier',
                    },
                    {
                      label: 'I’ve left the GP practice',
                      value: 'left_gp_practice',
                    },
                    {
                      label: 'I’m going on an extended holiday',
                      value: 'extended_holiday',
                    },
                  ]}
                  onChange={(v) => setOptOutReason(v)}
                />
              </Box>
            </Stack>

            <Checkbox
              label="I confirm I no longer want to use this service"
              checked={stopMessages}
              onChange={(e) => setStopMessages(e.target.checked)}
            />

            <Button
              variant="contained"
              size="large"
              fullWidth
              disabled={!optOutReason || !stopMessages}
              onClick={handleOptOut}
            >
              Next
            </Button>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default OptOut;
