import { InputAdornment, TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';

export default function WeightInputInKilograms() {
  return (
    <Field name="kg">
      {(fieldProps: FieldProps<number>) => {
        return (
          <TextField
            variant="outlined"
            type="number"
            placeholder="0"
            value={fieldProps.field.value}
            onChange={(event) =>
              fieldProps.form.setFieldValue('kg', event.target.value)
            }
            onBlur={fieldProps.field.onBlur}
            helperText={fieldProps.meta.error}
            error={!!fieldProps.meta.error}
            inputProps={{
              'aria-label': 'Weight in kg',
              inputMode: 'numeric',
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">KG</InputAdornment>,
            }}
          />
        );
      }}
    </Field>
  );
}
