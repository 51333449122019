import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import initialiseAvo from './analytics/initialiseAvo';
import App from './app/App';
import { initialiseSentry } from './app/sentry';
import './reset.css';

initialiseSentry();
initialiseAvo();

const container = document.querySelector('#root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </StrictMode>,
);
