export const linkToHomepage = () => '/';
export const linkToHelp = () => '/help';
export const linkToLogin = () => '/login';
export const linkToOnboardingRedirect = () => '/redirect';
export const linkToIntendedUse = () => '/intended-use';
export const linkToProfile = () => '/patient-profile';

export const linkToRescheduleAppointment = (appointmentId: string) =>
  `/appointments/${appointmentId}`;
export const linkToBookAppointment = () => '/book-appointment';

export const linkToSubmitBP = () => '/submit-bp';
export const linkToSubmitBPSubmitted = () => '/submit-bp/submitted';
