import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import Header from 'app/components/Header';
import PageLayout from 'app/components/PageLayout';
import { linkToHomepage } from 'app/routing/links';
import { useQuestionnaireId } from 'pages/Questionnaire/hooks/routing';
import { questionnaireAtomFamily } from 'pages/Questionnaire/state/questionnaire';

import {
  postSubmissionPageTextAtom,
  questionnaireAnswersAtom,
} from '../../state';

import careTeamImageSrc from './care_team.png';

const QuestionnaireCompleted = () => {
  const questionnaireId = useQuestionnaireId();

  const resetQuestionnaireAnswers = useResetRecoilState(
    questionnaireAnswersAtom,
  );
  const resetQuestionnaireState = useResetRecoilState(
    questionnaireAtomFamily(questionnaireId),
  );
  const pageCopy = useRecoilValue(postSubmissionPageTextAtom);

  useEffect(() => {
    resetQuestionnaireAnswers();
    resetQuestionnaireState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout>
      {{
        header: <Header />,
        body: (
          <Box
            height="100%"
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Stack spacing="32px" alignItems="center">
              <Typography variant="h2" component="h1" textAlign="center">
                Thank you for submitting your information
              </Typography>

              <Box
                component="img"
                sx={{
                  width: '100%',
                  maxHeight: '74px',
                  objectFit: 'contain',
                  content: `url(${careTeamImageSrc})`,
                }}
                alt="Online clinic team"
              />

              <Stack spacing="16px" alignItems="center">
                {pageCopy.map((paragraph, idx) => (
                  <Typography textAlign="center" variant="body2" key={idx}>
                    {paragraph}
                  </Typography>
                ))}
              </Stack>

              <Button
                variant="contained"
                size="large"
                role="button"
                fullWidth
                onClick={() => window.location.assign(linkToHomepage())}
              >
                Go home
              </Button>
            </Stack>
          </Box>
        ),
      }}
    </PageLayout>
  );
};

export default QuestionnaireCompleted;
