import {
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useEffect } from 'react';

import Avo from 'analytics/Avo';
import {
  currentDayValid,
  currentMonthValid,
  currentYearValid,
} from '../utils/dateLogic';

type Props = {
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setDayOfBirth: (value: string) => void;
  setMonthOfBirth: (value: string) => void;
  setYearOfBirth: (value: string) => void;
  firstName: string;
  lastName: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
};

const LoginFormFields = ({
  setFirstName,
  setLastName,
  setDayOfBirth,
  setMonthOfBirth,
  setYearOfBirth,
  firstName,
  lastName,
  dayOfBirth,
  monthOfBirth,
  yearOfBirth,
}: Props): ReactElement => {
  const monthOfBirthError = currentMonthValid(
    Number.parseInt(monthOfBirth, 10),
  );
  const yearOfBirthError = currentYearValid(Number.parseInt(yearOfBirth, 10));

  const dayOfBirthError = currentDayValid(
    Number.parseInt(dayOfBirth, 10),
    Number.parseInt(monthOfBirth, 10),
  );

  useEffect(() => {
    if (dayOfBirthError || monthOfBirthError || yearOfBirthError) {
      Avo.userSignInValidationErrorMessageViewed();
    }
  }, [dayOfBirthError, monthOfBirthError, yearOfBirthError]);

  return (
    <Stack spacing="32px">
      <Stack spacing="16px">
        <Typography variant="h1" component="h2">
          Let&apos;s confirm it&apos;s you
        </Typography>

        <Typography variant="body1">What&apos;s your name?</Typography>

        <Stack direction="row" spacing="16px">
          <FormControl fullWidth>
            <FormLabel htmlFor="first-name">First name</FormLabel>

            <TextField
              id="first-name"
              type="string"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel htmlFor="last-name">Last name</FormLabel>

            <TextField
              id="last-name"
              type="string"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack spacing="16px">
        <Typography variant="body1">What&apos;s your date of birth?</Typography>

        <Stack direction="row" spacing="16px">
          <FormControl fullWidth>
            <FormLabel htmlFor="day">Day (DD)</FormLabel>

            <TextField
              id="day"
              type="number"
              placeholder="DD"
              value={dayOfBirth}
              onChange={(e) => setDayOfBirth(e.target.value)}
              error={dayOfBirthError}
              inputProps={{
                sx: { paddingX: '12px', paddingY: '14px' },
                pattern: '[0-9]*',
                inputMode: 'numeric',
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel htmlFor="month">Month (MM)</FormLabel>

            <TextField
              id="month"
              type="number"
              placeholder="MM"
              value={monthOfBirth}
              onChange={(e) => setMonthOfBirth(e.target.value)}
              error={monthOfBirthError}
              inputProps={{
                sx: { paddingX: '12px', paddingY: '14px' },
                pattern: '[0-9]*',
                inputMode: 'numeric',
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel htmlFor="year">Year (YYYY)</FormLabel>

            <TextField
              id="year"
              type="number"
              placeholder="YYYY"
              value={yearOfBirth}
              onChange={(e) => setYearOfBirth(e.target.value)}
              error={yearOfBirthError}
              inputProps={{
                sx: { paddingX: '12px', paddingY: '14px' },
                pattern: '[0-9]*',
                inputMode: 'numeric',
              }}
            />
          </FormControl>
        </Stack>

        {yearOfBirthError ? (
          <Typography color="colors.red" variant="body2">
            Year selected is incorrect
          </Typography>
        ) : null}

        {monthOfBirthError ? (
          <Typography color="colors.red" variant="body2">
            Month selected is incorrect
          </Typography>
        ) : null}

        {dayOfBirthError ? (
          <Typography color="colors.red" variant="body2">
            Day selected is incorrect
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default LoginFormFields;
