import { Box, Button, Divider, Typography } from '@mui/material';

interface Props {
  title: string;
  value?: string;
  link?: string;
}

const InformationRow = ({ title, value, link }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ paddingY: '16px' }}>{title}</Typography>
        {value && <Typography>{value}</Typography>}
        {link && (
          <Button
            sx={{ marginRight: '-16px' }}
            type="text"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            View
          </Button>
        )}
      </Box>

      <Divider
        sx={{
          marginX: { xs: '-16px', sm: '0' },
        }}
      />
    </>
  );
};

export default InformationRow;
