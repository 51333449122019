import { Stack, SxProps } from '@mui/material';

import SelectableCard from 'app/components/SelectableCard';

interface IRadio {
  label: string;
  value: string;
}

interface Props {
  value: string;
  options: IRadio[];
  sx?: SxProps;
  onChange: (value: string) => void;
}

const SelectableCardGroup = ({
  options,
  onChange,
  value: currentValue,
}: Props) => (
  <Stack spacing="16px" role="radiogroup">
    {options.map(({ value, label }) => (
      <SelectableCard
        key={value}
        text={label}
        isSelected={value === currentValue}
        handleClick={() => onChange(value)}
      />
    ))}
  </Stack>
);

export default SelectableCardGroup;
