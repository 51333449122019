import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

import { patientDetailsSelector } from 'app/state';
import { logOutWithToken } from 'app/tokens';
import InformationRow from './InformationRow';

const handleLogOut = () => {
  logOutWithToken();
};

const ProfileTab = (): ReactElement => {
  const loggedInPatient = useRecoilValue(patientDetailsSelector);

  return (
    <Stack spacing="32px">
      <Stack spacing="4px">
        <Typography component="h1" variant="h2">
          {`${loggedInPatient?.name.first} ${loggedInPatient?.name.last}`}
        </Typography>

        <Stack>
          <InformationRow title="NHS No." value={loggedInPatient?.nhs_number} />

          <InformationRow
            title="Mobile"
            value={`0${loggedInPatient?.phone_numbers[0].number}`}
          />

          <InformationRow
            title="Practice"
            value={loggedInPatient?.practice.name}
          />
        </Stack>
      </Stack>

      <Stack spacing="8px">
        <Typography variant="h3" component="h2">
          Options
        </Typography>

        <Stack>
          <InformationRow
            title="Privacy notice"
            link="https://www.suvera.co.uk/legals/privacy-notice"
          />

          <InformationRow
            title="Contact support"
            link="https://patient.suvera.org.uk/support"
          />
        </Stack>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="text" color="error" onClick={handleLogOut}>
          Log out?
        </Button>
      </Box>
    </Stack>
  );
};

export default ProfileTab;
