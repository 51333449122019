import { useRecoilValue } from 'recoil';

import { patientNameSelector } from 'app/state';

const usePatientName = () => {
  const patientName = useRecoilValue(patientNameSelector);
  return patientName;
};

export default usePatientName;
