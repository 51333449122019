import { Box, Stack } from '@mui/material';

type Props = { children: { header: React.ReactNode; body: React.ReactNode } };

const PageLayout = ({ children: { header, body } }: Props) => (
  <Stack
    sx={{
      height: '100vh',
    }}
  >
    {header}

    <Box
      width="100%"
      maxWidth={{ sm: '472px', lg: '530px' }}
      paddingY={{ xs: '16px', sm: '28px', lg: '48px' }}
      paddingX={{ xs: '16px', sm: '0' }}
      marginX="auto"
      position="relative"
      height="100%"
    >
      {body}
    </Box>
  </Stack>
);

export default PageLayout;
