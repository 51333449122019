import { FC, ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const SuveraApiQueryProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      },
    },
  });

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export default SuveraApiQueryProvider;
