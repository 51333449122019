import { Stack } from '@mui/material';
import { z } from 'zod';
import { Formik, Form, Field, FieldProps } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { captureException } from '@sentry/react';
import { pipe, toSafeInteger } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { IQuestionCalculator } from 'pages/Questionnaire/api';
import BackButton from 'pages/Questionnaire/components/BackButton';
import NextButton from 'pages/Questionnaire/components/NextButton';
import ButtonRow from 'pages/Questionnaire/components/ButtonRow';

import HeightInputInCentimetres from './HeightInputInCentimetres';
import HeightInputInFeet from './HeightInputInFeet';
import QuestionLayout from './QuestionLayout';
import UnitToggle from './UnitToggle';
import { useAnswerState } from '../hooks/useAnswerState';
import useBackClick from '../hooks/useBackClick';
import useSubmitQuestion from '../hooks/useSubmitQuestion';
import { convertToCentimetres } from '../utils/convertToCentimetres';
import { convertToFeetAndInches } from '../utils/convertToFeetAndInches';

type Props = {
  question: IQuestionCalculator;
  nextStepUrl: string;
};

const formatFeetAndInches = ([feet, inches]: [number, number]) =>
  `${feet}'${inches}"`;

const formatHeightImperial = pipe(convertToFeetAndInches, formatFeetAndInches);

const ZDecodedError = z.union([
  z
    .tuple([
      z
        .object({ code: z.literal('too_big'), maximum: z.number() })
        .passthrough(),
    ])
    .rest(z.any()),
  z
    .tuple([
      z
        .object({ code: z.literal('too_small'), minimum: z.number() })
        .passthrough(),
    ])
    .rest(z.any()),
  z.tuple([z.object({ code: z.string() }).passthrough()]).rest(z.any()),
]);

const translateZodError = (errorJson: string) => {
  if (!errorJson) return '';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedErrorJson = JSON.parse(errorJson);
  if (ZDecodedError.safeParse(parsedErrorJson).success !== true) {
    return '';
  }
  const decodedError = ZDecodedError.parse(parsedErrorJson)[0];
  switch (decodedError.code) {
    case 'too_big': {
      return `Must be less than ${formatHeightImperial(decodedError.maximum)}`;
    }
    case 'too_small': {
      return `Must be greater than ${formatHeightImperial(
        decodedError.minimum,
      )}`;
    }
    default: {
      captureException(new Error(`Unknown validation error - ${errorJson}`));
      return 'Invalid height';
    }
  }
};

type FormikValues = {
  in: string;
  ft: string;
  cm: string;
  unit: 'metric' | 'imperial';
};

const ZMetricHeight = (schema: IQuestionCalculator['schema']) =>
  z.coerce
    .number()
    .min(schema.minimum, `Must be greater than ${schema.minimum}cm`)
    .max(schema.maximum, `Must be less than ${schema.maximum}cm`);

const validateFormValues =
  (schema: IQuestionCalculator['schema']) => (values: FormikValues) => {
    if (values.unit === 'imperial') {
      if (values.ft === '' && values.in === '') {
        return {};
      }

      const metricValue = convertToCentimetres(
        toSafeInteger(values.ft),
        toSafeInteger(values.in),
      );

      const metricValueParsed = ZMetricHeight(schema).safeParse(metricValue);
      if (metricValueParsed.success === false) {
        return {
          ft: translateZodError(metricValueParsed.error.message),
          in: translateZodError(metricValueParsed.error.message),
        };
      }
    }
    return {};
  };

const createZodSchema = (schema: IQuestionCalculator['schema']) =>
  z.object({
    cm: ZMetricHeight(schema).optional(),
    in: z.coerce
      .number()
      .min(0)
      .max(11, 'Cannot be more than 11 inches')
      .optional(),
  });

const HeightCalculator = ({ question, nextStepUrl }: Props) => {
  const metricId = question.metric;
  const [answer, setAnswer] = useAnswerState(metricId);

  const handleClickBack = useBackClick();

  const handleSubmit = useSubmitQuestion();

  const zodSchemaForForm = useMemo(
    () => createZodSchema(question.schema),
    [question],
  );

  const validateForm = useCallback(
    (values: FormikValues) => {
      return validateFormValues(question.schema)(values);
    },
    [question],
  );

  return (
    <QuestionLayout question={question} nextStepUrl={nextStepUrl}>
      <Formik
        initialValues={{
          cm: answer.value?.toString() || '',
          ft: answer.valueInFeet?.toString() || '',
          in: answer.valueInInches?.toString() || '',
          unit: answer.unit || 'metric',
        }}
        validationSchema={toFormikValidationSchema(zodSchemaForForm)}
        validateOnChange={false}
        validate={validateForm}
        onSubmit={({ unit, cm, ft, in: inches }) => {
          const valueInMetric =
            unit === 'imperial'
              ? convertToCentimetres(toSafeInteger(ft), toSafeInteger(inches))
              : toSafeInteger(cm);
          setAnswer({
            unit,
            value: valueInMetric || undefined,
            valueInFeet: toSafeInteger(ft),
            valueInInches: toSafeInteger(inches),
          });
          return handleSubmit();
        }}
      >
        {(formikProps) => {
          return (
            <Form style={{ marginTop: '8px' }}>
              <Stack
                spacing={{ xs: '20px', sm: '28px' }}
                paddingBottom={{ xs: '82px', sm: '138px' }}
              >
                {formikProps.values.unit === 'metric' ? (
                  <HeightInputInCentimetres />
                ) : (
                  <HeightInputInFeet />
                )}
                <Field name="unit">
                  {(fieldProps: FieldProps<FormikValues['unit']>) => (
                    <UnitToggle
                      value={fieldProps.field.value}
                      onChange={(value) => {
                        return fieldProps.form.setValues({
                          cm: '',
                          unit: value,
                          ft: '',
                          in: '',
                        });
                      }}
                      metricUnit="cm"
                      imperialUnit="ft"
                    />
                  )}
                </Field>
              </Stack>
              <ButtonRow>
                <BackButton onClick={handleClickBack} />
                <NextButton />
              </ButtonRow>
            </Form>
          );
        }}
      </Formik>
    </QuestionLayout>
  );
};

export default HeightCalculator;
