import { linkToHelpBpTutorialStep } from 'pages/PostLogin/HelpSectionBpTutorial/links';
import {
  linkToOptOut,
  linkToOptOutSubmitted,
} from 'pages/PostLogin/OptOut/links';
import {
  linkToSendMessage,
  linkToSendMessageSubmitted,
} from 'pages/PostLogin/SendMessage/links';
import { linkToSupport } from 'pages/PostLogin/Support/links';

import {
  linkToBookAppointment,
  linkToHelp,
  linkToHomepage,
  linkToIntendedUse,
  linkToLogin,
  linkToOnboardingRedirect,
  linkToProfile,
  linkToRescheduleAppointment,
  linkToSubmitBP,
  linkToSubmitBPSubmitted,
} from './links';

export const PATHS = {
  bp_tutorial_step_1: linkToHelpBpTutorialStep('1'),
  bp_tutorial_step_2: linkToHelpBpTutorialStep('2'),
  bp_tutorial_step_3: linkToHelpBpTutorialStep('3'),
  bp_tutorial_step_4: linkToHelpBpTutorialStep('4'),
  book_appointment: linkToBookAppointment(),
  help: linkToHelp(),
  homepage: linkToHomepage(),
  login: linkToLogin(),
  opt_out: linkToOptOut(),
  opt_out_submitted: linkToOptOutSubmitted(),
  profile: linkToProfile(),
  reschedule_appointment: linkToRescheduleAppointment(':appointmentID'),
  send_message: linkToSendMessage(),
  send_message_submitted: linkToSendMessageSubmitted(),
  submit_bp: linkToSubmitBP(),
  submit_bp_submitted: linkToSubmitBPSubmitted(),
  support: linkToSupport(),

  LEGACY_intended_use: linkToIntendedUse(),
  LEGACY_onboarding_redirect: linkToOnboardingRedirect(),
};
