import { Box, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';

const ButtonRow: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box width="100%" padding="16px">
      <Box maxWidth={{ sm: '472px', lg: '530px' }} marginX="auto">
        <Stack direction="row" spacing="8px">
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

export default ButtonRow;
