import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { logSentryError, logInfoToSentry } from 'app/sentry';

import { suveraApiAccessTokenSelector } from './state';
import { hasJwtExpired } from './tokens';

const useDetectExpiredTokens = () => {
  const accessToken = useRecoilValue(suveraApiAccessTokenSelector);
  useEffect(() => {
    if (!accessToken) return () => {};
    if (hasJwtExpired(accessToken)) {
      return logInfoToSentry("User's access token has expired");
    }

    const id = setInterval(() => {
      if (hasJwtExpired(accessToken)) {
        logSentryError("User's access token has expired");
        clearInterval(id);
      }
    }, 60 * 1000);

    return () => {
      clearInterval(id);
    };
  }, [accessToken]);
};

export default useDetectExpiredTokens;
