import { Navigate, Route } from 'react-router-dom';

import { PATHS } from 'app/routing/paths';

import { linkToHelpBpTutorial, linkToHelpBpTutorialStep } from './links';

import HelpSectionBpTutorial from '.';

const routes = [
  <Route
    path={linkToHelpBpTutorial()}
    element={<Navigate to={linkToHelpBpTutorialStep('1')} />}
    key="tutorialRedirect"
  />,
  <Route
    path={PATHS.bp_tutorial_step_1}
    element={<HelpSectionBpTutorial stepId="one" />}
    key="tutorial1"
  />,
  <Route
    path={PATHS.bp_tutorial_step_2}
    element={<HelpSectionBpTutorial stepId="two" />}
    key="tutorial2"
  />,
  <Route
    path={PATHS.bp_tutorial_step_3}
    element={<HelpSectionBpTutorial stepId="three" />}
    key="tutorial3"
  />,
  <Route
    path={PATHS.bp_tutorial_step_4}
    element={<HelpSectionBpTutorial stepId="four" />}
    key="tutorial4"
  />,
];

export default routes;
