import { useQuestionId } from './routing';
import { useQuestionnaire } from './selectors';

import getAdjacentStepUrls from '../state/getAdjacentStepUrls';

// eslint-disable-next-line import/prefer-default-export
export function useGetAdjacentStepUrls(stepId?: string) {
  const questionId = useQuestionId();
  const { questions, _id: questionnaireId } = useQuestionnaire();

  if (stepId) {
    return getAdjacentStepUrls(questionnaireId, stepId, questions);
  }

  if (questionId) {
    return getAdjacentStepUrls(questionnaireId, questionId, questions);
  }

  return { previousStepUrl: '', nextStepUrl: '' };
}
