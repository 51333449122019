export const currentYearValid = (yearOfBirth: number): boolean => {
  const currentYear = new Date().getFullYear();
  if (yearOfBirth >= currentYear) {
    return true;
  }
  return false;
};

export const currentMonthValid = (monthOfBirth: number): boolean => {
  if (monthOfBirth > 12) {
    return true;
  }
  return false;
};

export const currentDayValid = (
  dayOfbirth: number,
  monthOfBirth: number,
): boolean => {
  const thirtyDayMonthsSet = new Set([4, 6, 9, 11]);
  const february = 2;

  if (dayOfbirth > 31) {
    return true;
  }

  if (monthOfBirth === february && dayOfbirth > 29) {
    return true;
  }

  if (thirtyDayMonthsSet.has(monthOfBirth) && dayOfbirth > 30) {
    return true;
  }

  return false;
};
