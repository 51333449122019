import { Stack, Typography } from '@mui/material';
import Avo from 'analytics/Avo';

import PageLayout from 'app/components/PageLayout';
import QuestionnaireHeader from 'pages/Questionnaire/components/QuestionnaireHeader';
import QuestionnaireCTA from 'pages/Questionnaire/components/QuestionnaireCta';
import BackButton from 'pages/Questionnaire/components/BackButton';
import ButtonRow from 'pages/Questionnaire/components/ButtonRow';
import { useQuestionnaireId } from 'pages/Questionnaire/hooks/routing';
import { useGetAdjacentStepUrls } from 'pages/Questionnaire/hooks/steps';
import { useNavigate } from 'react-router-dom';

import ECommonStep from '../../types/ECommonStep';

const QuestionnaireDisclaimer = () => {
  const { nextStepUrl, previousStepUrl } = useGetAdjacentStepUrls(
    ECommonStep.DISCLAIMER,
  );
  const navigate = useNavigate();
  const questionnaireId = useQuestionnaireId();

  const disclaimerMessage = () => {
    switch (questionnaireId) {
      case 'asthma': {
        return "If any of your asthma symptoms are much worse than normal or you're having a serious asthma attack, please call 999.\n\nThese symptoms might include:\n\n• Severe shortness of breath or wheezing.\n• Difficulty speaking\n• Feeling drowsy or confused";
      }
      default: {
        return "If you're having an emergency or have an urgent concern, call 111 or go to A&E";
      }
    }
  };

  const handleClickBack = () => {
    navigate(previousStepUrl);

    Avo.questionnaireStepBackButtonClicked({
      questionnaireType: questionnaireId,
      stepId: ECommonStep.DISCLAIMER,
      questionIndex: undefined,
      totalQuestionCount: undefined,
    });
  };

  const handleClickNext = () => {
    navigate(nextStepUrl);

    Avo.questionnaireStepNextButtonClicked({
      questionnaireType: questionnaireId,
      stepId: ECommonStep.DISCLAIMER,
      questionIndex: undefined,
      totalQuestionCount: undefined,
    });
  };

  return (
    <PageLayout>
      {{
        header: <QuestionnaireHeader stepId={ECommonStep.DISCLAIMER} />,
        body: (
          <Stack
            sx={{
              height: '100%',
              justifyContent: 'flex-end',
              rowGap: '50%',
            }}
          >
            <Stack direction="column" justifyContent="center" rowGap="5px">
              <Typography textAlign="center" component="h1" variant="h2">
                Before you start
              </Typography>
              <Typography
                textAlign="center"
                variant="body2"
                style={{ whiteSpace: 'pre-line' }}
              >
                {disclaimerMessage()}
              </Typography>
            </Stack>

            <Stack>
              <ButtonRow>
                <BackButton onClick={handleClickBack} />
                <QuestionnaireCTA onClick={handleClickNext}>
                  Next
                </QuestionnaireCTA>
              </ButtonRow>
            </Stack>
          </Stack>
        ),
      }}
    </PageLayout>
  );
};

export default QuestionnaireDisclaimer;
