import { Button, Grid, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageLayout from 'app/components/PageLayout';
import SelectableCardGroup from 'app/components/SelectableCardGroup';
import SimpleHeaderBar from 'app/components/SimpleHeaderBar';

import { linkToOptOut } from '../OptOut/links';
import { linkToSendMessage } from '../SendMessage/links';

const Support = (): ReactElement => {
  const [supportOption, setSupportOption] = useState<string>('');

  const navigate = useNavigate();

  const appointmentTypeId = '36729257';

  return (
    <PageLayout>
      {{
        header: (
          <SimpleHeaderBar
            buttonText="Back"
            buttonClicked={() => {
              navigate('/login?redirect=/support');
            }}
          />
        ),
        body: (
          <Grid
            container
            spacing="16px"
            alignSelf="center"
            direction="row"
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Typography variant="h2" component="h1">
                {"We're here to help"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                You can send a written message to our care team, book a call
                with a care team member, or let us know you don’t want to use
                this service.
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Please note, if you need urgent care, dial 111 or consider going
                to A&E.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={600}>
                What would you like to do?
              </Typography>
            </Grid>
            <Grid item xs={12} marginBottom="16px">
              <SelectableCardGroup
                value={supportOption}
                options={[
                  {
                    label: 'Book a telephone appointment',
                    value: `/book-appointment?type=${appointmentTypeId}`,
                  },
                  {
                    label: 'Send a message',
                    value: linkToSendMessage(),
                  },
                  {
                    label: 'Opt out of the online clinic',
                    value: linkToOptOut(),
                  },
                ]}
                onChange={(v) => setSupportOption(v)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                disabled={!supportOption}
                onClick={() => navigate(supportOption)}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        ),
      }}
    </PageLayout>
  );
};

export default Support;
