import { Divider, Grid, Typography } from '@mui/material';

type Props = {
  title: string;
  systolic: string;
  diastolic: string;
  pulse?: string;
};

const ConfirmationRow = ({ title, systolic, diastolic, pulse }: Props) => {
  return (
    <Grid container item xs={12} rowSpacing="8px">
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Typography>SYS {systolic}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>DIA {diastolic}</Typography>
        </Grid>

        <Grid item xs={4}>
          {pulse && <Typography>PULSE {pulse}</Typography>}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ marginX: { xs: '-16px', sm: '0px' } }} />
      </Grid>
    </Grid>
  );
};

export default ConfirmationRow;
