import { Button, Grid, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';

import { BpReading, BpReadingValues } from '../types';
import SingleBpSubmission from './SingleBpSubmission';

type Props = {
  values: BpReadingValues;
  onFinishEnteringBpReadings: () => void;
};

const isBpReadingComplete = (bpReading: BpReading): boolean => {
  return bpReading.sys.value !== null && bpReading.dia.value !== null;
};

const findInvalidBpGap = (values: BpReadingValues): number => {
  const bpGaps = [
    +(values.first.sys.value || 0) - +(values.first.dia.value || 0),
    +(values.second.sys.value || 0) - +(values.second.dia.value || 0),
    +(values.third.sys.value || 0) - +(values.third.dia.value || 0),
  ];

  const allValuesComplete =
    isBpReadingComplete(values.first) &&
    isBpReadingComplete(values.second) &&
    isBpReadingComplete(values.third);

  const bpGapsSorted = [...bpGaps].sort((a, b) => b - a);

  const invalidGap =
    allValuesComplete &&
    bpGapsSorted[0] > 85 &&
    bpGapsSorted[1] * 1.66 < bpGapsSorted[0]
      ? bpGaps.indexOf(bpGapsSorted[0])
      : -1;

  return invalidGap;
};

const BpSubmissionForm = ({
  values,
  onFinishEnteringBpReadings,
}: Props): ReactElement => {
  const [firstReadingIsValid, setFirstReadingIsValid] = useState(false);
  const [secondReadingIsValid, setSecondReadingIsValid] = useState(false);
  const [thirdReadingIsValid, setThirdReadingIsValid] = useState(false);

  const invalidBpGap = findInvalidBpGap(values);

  return (
    <Grid container rowSpacing="24px">
      <Grid container item xs={12} rowSpacing="8px">
        <Grid item xs={12}>
          <Typography variant="h2" component="h1">
            Record your blood pressure
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            If you&apos;re experiencing a medical emergency please dial 999
            immediately
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <SingleBpSubmission
          testId="blood_pressure_values_1"
          title="Take your first reading"
          sys={values.first.sys}
          dia={values.first.dia}
          pulse={values.first.pulse}
          onValidationUpdate={(valid) => setFirstReadingIsValid(valid)}
          hasInvalidGap={invalidBpGap === 0}
        />
      </Grid>

      <Grid item xs={12}>
        <SingleBpSubmission
          testId="blood_pressure_values_2"
          title="Wait 1 minute, then take your second reading"
          sys={values.second.sys}
          dia={values.second.dia}
          pulse={values.second.pulse}
          onValidationUpdate={(valid) => setSecondReadingIsValid(valid)}
          hasInvalidGap={invalidBpGap === 1}
        />
      </Grid>

      <Grid item xs={12}>
        <SingleBpSubmission
          testId="blood_pressure_values_3"
          title="Wait 1 minute, then take your third reading"
          sys={values.third.sys}
          dia={values.third.dia}
          pulse={values.third.pulse}
          onValidationUpdate={(valid) => setThirdReadingIsValid(valid)}
          hasInvalidGap={invalidBpGap === 2}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={
            !firstReadingIsValid ||
            !secondReadingIsValid ||
            !thirdReadingIsValid
          }
          onClick={() => {
            onFinishEnteringBpReadings();
          }}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default BpSubmissionForm;
