import { Route } from 'react-router-dom';

import LoginCheck from 'app/LoginCheck';
import { PATHS } from 'app/routing/paths';
import Support from 'pages/PostLogin/Support';

const routes = [
  <Route
    path={PATHS.support}
    element={<LoginCheck element={<Support />} />}
    key="support"
  />,
];

export default routes;
