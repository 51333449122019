import getStepsFromQuestions from './getStepsFromQuestions';

import { IQuestion } from '../api';
import {
  linkToCommonQuestionnaireStep,
  linkToQuestionnaireQuestion,
} from '../links';
import { IStep } from '../types/IStep';

const getUrlFromStep = (questionnaireId: string, step: IStep | null) => {
  if (step === null) return '';
  if (step.isCommonStep)
    return linkToCommonQuestionnaireStep(questionnaireId, step._id);
  return linkToQuestionnaireQuestion(questionnaireId, step._id);
};

export default function getAdjacentStepUrls(
  questionnaireId: string,
  stepId: string,
  questions: IQuestion[],
) {
  const steps = getStepsFromQuestions(questions);
  const currentStepIndex = steps.findIndex((step) => step._id === stepId);

  const previousStep =
    currentStepIndex === 0 ? null : steps[currentStepIndex - 1];

  const previousStepUrl = getUrlFromStep(questionnaireId, previousStep);

  const nextStep =
    currentStepIndex === steps.length - 1 ? null : steps[currentStepIndex + 1];
  const nextStepUrl = getUrlFromStep(questionnaireId, nextStep);

  return { nextStepUrl, previousStepUrl };
}
