import { Navigate, Route, useNavigate } from 'react-router-dom';

import LoginScreen from 'pages/Onboarding/LoginScreen';
import BookAppointment from 'pages/PostLogin/BookAppointment';
import BpSubmission from 'pages/PostLogin/BpSubmission';
import HelpLandingPage from 'pages/PostLogin/HelpLandingPage';
import helpRoutes from 'pages/PostLogin/HelpSectionBpTutorial/routes';
import Homepage from 'pages/PostLogin/Homepage';
import optOutRoutes from 'pages/PostLogin/OptOut/routes';
import PageNotFound from 'pages/PostLogin/PageNotFound';
import RescheduleAppointment from 'pages/PostLogin/RescheduleAppointment';
import sendMessageRoutes from 'pages/PostLogin/SendMessage/routes';
import supportRoutes from 'pages/PostLogin/Support/routes';
import Questionnaire from 'pages/Questionnaire';

import Avo from 'analytics/Avo';
import { FC, useEffect } from 'react';

import QuestionnaireCompleted from 'pages/Questionnaire/pages/QuestionnaireCompleted';
import { PATHS } from './paths';
import { linkToHomepage, linkToSubmitBP } from './links';
import LoginCheck from '../LoginCheck';
import { SentryTestErrorComponent } from '../sentry';

const TrackedRedirectForDeprecatedRoute: FC<{ pageName: string }> = ({
  pageName,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    Avo.pageViewed({ customDestinationPageName_: pageName });
    navigate(linkToHomepage(), { replace: true });
  }, [navigate, pageName]);

  return null;
};

const otherRoutes = [
  <Route
    path="/__error"
    element={<SentryTestErrorComponent />}
    key="SentryTestError"
  />,
  <Route
    path="/questionnaire/:questionnaireId/*"
    element={<LoginCheck element={<Questionnaire />} />}
    key="questionnaire"
  />,
  <Route path={PATHS.login} element={<LoginScreen />} key="login" />,
  <Route path={PATHS.help} element={<HelpLandingPage />} key="help" />,
  <Route
    path={PATHS.book_appointment}
    element={<LoginCheck element={<BookAppointment />} />}
    key="book_appointment"
  />,
  <Route
    path={PATHS.reschedule_appointment}
    element={<LoginCheck element={<RescheduleAppointment />} />}
    key="reschedule_appointment"
  />,
  <Route
    path={PATHS.submit_bp}
    element={<LoginCheck element={<BpSubmission />} />}
    key="submit_bp"
  />,
  <Route
    path={PATHS.submit_bp_submitted}
    element={<LoginCheck element={<QuestionnaireCompleted />} />}
    key="submit_bp_submitted"
  />,
  <Route
    path={PATHS.profile}
    element={<LoginCheck element={<Homepage currentTabIndex={1} />} />}
    key="profile"
  />,
  <Route
    path={PATHS.homepage}
    element={<LoginCheck element={<Homepage currentTabIndex={0} />} />}
    key="homepage"
  />,
  <Route path="/*" element={<PageNotFound />} key="not-found" />,
];

const legacyRedirectRoutes = [
  <Route
    path={PATHS.LEGACY_intended_use}
    element={
      <TrackedRedirectForDeprecatedRoute pageName="legacy_intended_use" />
    }
    key="legacy_intended_use"
  />,
  <Route
    path={PATHS.LEGACY_onboarding_redirect}
    element={
      <TrackedRedirectForDeprecatedRoute pageName="legacy_onboarding_redirect" />
    }
    key="legacy_onboarding_redirect"
  />,
  <Route
    path="/:id/submit-bp"
    element={<Navigate replace to={linkToSubmitBP()} />}
    key="legacy_submit_bp"
  />,
];

const routes = [
  ...optOutRoutes,
  ...supportRoutes,
  ...sendMessageRoutes,
  ...helpRoutes,
  ...legacyRedirectRoutes,
  ...otherRoutes,
];

export default routes;
