import { useRecoilValue } from 'recoil';

import { suveraApiAccessTokenSelector } from 'app/state';

const useAccessToken = () => {
  const token = useRecoilValue(suveraApiAccessTokenSelector);
  if (!token) throw new Error('Access token not found in state');
  return token;
};

export default useAccessToken;
