import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import PageLayout from 'app/components/PageLayout';
import SimpleHeaderBar from 'app/components/SimpleHeaderBar';
import { linkToHelp } from 'app/routing/links';

import StepContent from './components/StepContent';
import { StepId, TUTORIAL_STEPS_CONFIG } from './consts';

type Props = {
  stepId: StepId;
};

const HelpSectionBpTutorial: FC<Props> = ({ stepId }) => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      {{
        header: (
          <SimpleHeaderBar
            buttonText="Back"
            buttonClicked={() => navigate(linkToHelp())}
          />
        ),
        body: <StepContent stepConfig={TUTORIAL_STEPS_CONFIG[stepId]} />,
      }}
    </PageLayout>
  );
};

export default HelpSectionBpTutorial;
