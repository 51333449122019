import { Component, ErrorInfo, ReactNode } from 'react';

import { logSentryError } from '../sentry';

const logError = (error: Error, errorInfo: ErrorInfo) => {
  logSentryError(error);
  // eslint-disable-next-line no-console
  console.error(
    'Error caught in error boundary',
    error.message,
    error,
    errorInfo.componentStack,
  );
};

interface Props {
  children: ReactNode;
}

export default class ErrorBoundary extends Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    const { children } = this.props;

    return children;
  }
}
