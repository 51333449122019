import { atom } from 'recoil';

export type IQuestionnaireCalculatorAnswer = {
  unit: 'metric' | 'imperial' | undefined;
  value: string | number | undefined;
  [key: string]: string | number | undefined;
};

type IBPReading = {
  sys: number;
  dia: number;
  pulse?: number;
};

export type EQuestionnaireBaseAnswer =
  | number
  | string
  | string[]
  | IQuestionnaireCalculatorAnswer;

export type EQuestionnaireAnswer = EQuestionnaireBaseAnswer | IBPReading[];

export type IQuestionnaireAnswers = {
  [key: string]: EQuestionnaireAnswer;
};

// eslint-disable-next-line import/prefer-default-export
export const questionnaireAnswersAtom = atom<IQuestionnaireAnswers>({
  key: 'questionnaireAnswersAtom',
  default: {},
});

export const postSubmissionPageTextAtom = atom<string[]>({
  key: 'postSubmissionPageVariantAtom',
  default: [
    'Our team will look over your answers and organise your appointment.',
    'We review your answers between 8am-6pm Monday-Friday, excluding bank holidays.',
  ],
});
