import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import useAccessToken from 'app/hooks/useAccessToken';
import usePatientId from 'app/hooks/usePatientId';
import { BASE_URL } from 'app/suvera-api';

import { IQuestionnaireAnswers } from '../state';

type IArgs = {
  questionnaireId: string;
  answers: IQuestionnaireAnswers;
};

export const usePostQuestionnaireComplete = () => {
  const token = useAccessToken();
  const patientId = usePatientId();

  return useMutation({
    mutationFn: ({ questionnaireId, answers }: IArgs) =>
      axios.post<{
        message: string;
        result: {
          postSubmissionPageText: string[];
        };
        success: boolean;
      }>(
        `${BASE_URL}/patients/${patientId}/questionnaire/${questionnaireId}/complete`,
        { answers },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      ),
    onError: (error: Error | AxiosError) => {
      captureException(error);
    },
  });
};
