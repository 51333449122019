import Button from '@mui/material/Button';
import { FC } from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';

type BackButtonProps = {
  loading?: boolean;
  onClick?: () => void;
};

const BackButton: FC<BackButtonProps> = ({ loading = false, onClick }) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={loading}
      aria-label="Back"
      sx={{
        minWidth: '48px',
        padding: '10px',
        boxSizing: 'border-box',
        backgroundColor: 'colors.white',
        '&:hover': {
          backgroundColor: 'colors.blue.pale',
        },
      }}
    >
      <ArrowBackIosNew />
    </Button>
  );
};

export default BackButton;
