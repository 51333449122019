import {
  FormControlLabel,
  Checkbox as ICheckbox,
  CheckboxProps,
} from '@mui/material';
import { ReactElement } from 'react';

interface ICheckboxProps extends CheckboxProps {
  label?: string;
}

const Checkbox = (props: ICheckboxProps): ReactElement => {
  const { label, ...lProps } = props;

  if (label)
    return (
      <FormControlLabel label={label} control={<ICheckbox {...lProps} />} />
    );
  return <ICheckbox {...lProps} />;
};

export default Checkbox;
