import { Box, Typography } from '@mui/material';
import { practiceNameSelector } from 'app/state';
import { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

const RemoteClinicLogo = (): ReactElement => {
  const practiceName = useRecoilValue(practiceNameSelector);

  return (
    <Box>
      <Typography variant="body2" fontWeight="bold">
        {practiceName || 'Online Clinic'}
      </Typography>
    </Box>
  );
};

export default RemoteClinicLogo;
