import { Theme } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export function setupNavStyle(theme: Theme) {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    alignItems: 'center',
    padding: '12px 16px',
    width: '100%',
    height: '56px',
    backgroundColor: 'white',
    border: `0.5px solid ${theme.palette.divider}`,
    zIndex: 1,
  };
}
