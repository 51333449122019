import { Button, Divider, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FC } from 'react';

interface ConfirmBloodPressureSubmissionDialogueProps {
  visible: boolean;
  onContinue: () => void;
  onCancel: () => void;
}

const ConfirmBloodPressureSubmissionDialogue: FC<
  ConfirmBloodPressureSubmissionDialogueProps
> = ({ visible, onCancel, onContinue }) => (
  <Dialog
    open={visible}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent sx={{ textAlign: 'center' }}>
      <Stack spacing="16px">
        <Typography component="h1" variant="h2" id="alert-dialog-title">
          You submitted recently
        </Typography>

        <Typography id="alert-dialog-description">
          Please ensure readings have been taken across multiple days and times
        </Typography>
      </Stack>
    </DialogContent>

    <Divider />

    <Stack direction="row">
      <Button sx={{ flex: 1 }} onClick={onCancel} type="button" variant="text">
        Cancel
      </Button>

      <Divider orientation="vertical" sx={{ height: '48px' }} />

      <Button
        sx={{ flex: 1 }}
        onClick={onContinue}
        type="button"
        variant="text"
      >
        Continue
      </Button>
    </Stack>
  </Dialog>
);

export default ConfirmBloodPressureSubmissionDialogue;
