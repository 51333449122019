import { Box } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { linkToHelp } from 'app/routing/links';
import { patientDetailsSelector } from 'app/state';

import Avo from 'analytics/Avo';
import PageLayout from 'app/components/PageLayout';

import BpSubmissionConfirmation from './components/BpSubmissionConfirmation';
import BpSubmissionForm from './components/BpSubmissionForm';
import HeaderBar from './components/HeaderBar';
import { Stateful } from './types';

function useStateful<T>(): Stateful<T> {
  const state = useState<T | null>(null);
  return {
    value: state[0],
    setter: state[1],
  };
}

const BpSubmission = (): ReactElement => {
  const values = {
    first: {
      sys: useStateful<string>(),
      dia: useStateful<string>(),
      pulse: useStateful<string>(),
    },
    second: {
      sys: useStateful<string>(),
      dia: useStateful<string>(),
      pulse: useStateful<string>(),
    },
    third: {
      sys: useStateful<string>(),
      dia: useStateful<string>(),
      pulse: useStateful<string>(),
    },
  };

  const [bpSubmissionStep, setBpSubmissionStep] = useState<'EDIT' | 'CONFIRM'>(
    'EDIT',
  );

  const onFinishEnteringBpReadings = () => {
    setBpSubmissionStep('CONFIRM');
  };

  const goBackToEditScreen = () => {
    setBpSubmissionStep('EDIT');
  };

  const loggedInPatient = useRecoilValue(patientDetailsSelector);
  const navigate = useNavigate();

  useEffect(() => {
    // check if patient has an active submit bp task. If not, redirect to homepage
    const patientHasActiveSubmitBpTask = loggedInPatient?.active_tasks.some(
      (activeTask) =>
        activeTask.task_type_id === 'submit_bp' ||
        activeTask.task_type_id === 'submit_triage_bp',
    );
    if (!patientHasActiveSubmitBpTask) {
      navigate('/');
    }
  }, [loggedInPatient?.active_tasks, navigate]);

  return (
    <PageLayout>
      {{
        header: (
          <HeaderBar
            helpClicked={() => {
              window.open(linkToHelp(), '_blank')?.focus();
              Avo.helpButtonClicked({ location: 'submit_bp' });
            }}
          />
        ),
        body: (
          <Box marginTop="56px">
            {bpSubmissionStep === 'EDIT' ? (
              <BpSubmissionForm
                values={values}
                onFinishEnteringBpReadings={onFinishEnteringBpReadings}
              />
            ) : (
              <BpSubmissionConfirmation
                values={values}
                goBackToEditScreen={goBackToEditScreen}
              />
            )}
          </Box>
        ),
      }}
    </PageLayout>
  );
};

export default BpSubmission;
