import { Box, SxProps, Theme } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import RemoteClinicLogo from 'app/components/RemoteClinicLogo';
import { setupNavStyle } from 'app/components/TopNavigation/index.styled';
import { linkToRescheduleAppointment } from 'app/routing/links';
import {
  loggedInPatientAppointmentsSelector,
  patientDetailsSelector,
} from 'app/state';

import {
  ACUITY_EMBED_BASE_URL,
  ACUITY_OWNER_ID,
  APPOINTMENT_GROUP_TYPES,
  PATIENT_ID_FIELD_ID,
  ACUITY_PATIENT_DASHBOARD_LINK_FIELD_ID,
  ACUITY_PRACTICE_NAME_FIELD_ID,
} from './configuration';
import { linkToPatient } from './dashboardLinks';

const BookAppointment = (): ReactElement => {
  // This page is behind login check
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const loggedInPatient = useRecoilValue(patientDetailsSelector)!;

  const [searchParams] = useSearchParams();
  const { appointmentType, calendarID, datetime } = {
    appointmentType: searchParams.get('type'),
    calendarID: searchParams.get('calendar'),
    datetime: searchParams.get('datetime'),
  };

  const patientAppointments = useRecoilValue(
    loggedInPatientAppointmentsSelector,
  );
  const navigate = useNavigate();
  useEffect(() => {
    const matchingAppointment = patientAppointments?.find((appointment) => {
      const future = new Date(appointment.datetime).getTime() > Date.now();
      const matchesType = appointment.type.type_id === appointmentType;
      return future && matchesType && !appointment.cancelled;
    });
    if (matchingAppointment)
      navigate(linkToRescheduleAppointment(matchingAppointment._id));
  });

  const queryParameters = {
    // Required parameters
    owner: ACUITY_OWNER_ID,
    // Auto-populate required hidden fields
    firstName: loggedInPatient.name.first,
    lastName: loggedInPatient.name.last,
    [PATIENT_ID_FIELD_ID]: loggedInPatient._id,
    [ACUITY_PATIENT_DASHBOARD_LINK_FIELD_ID]: linkToPatient(loggedInPatient),
    [ACUITY_PRACTICE_NAME_FIELD_ID]: loggedInPatient.practice.name,
    // Auto-populate optional, user editable fields
    phone: `+44${loggedInPatient?.phone_numbers[0].number}`,
    email: loggedInPatient.email ?? '',
    // Pass through URL query parameters
    ...(appointmentType && {
      appointmentType:
        APPOINTMENT_GROUP_TYPES[appointmentType] ?? appointmentType,
    }),
    ...(!appointmentType &&
      calendarID && { appointmentType: 'category:Default' }),
    ...(calendarID && { calendarID }),
    ...(datetime && { datetime }),
  };

  const queryString = new URLSearchParams(queryParameters).toString();

  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={setupNavStyle as SxProps<Theme>} position="fixed" top={0}>
        <RemoteClinicLogo />
      </Box>
      <iframe
        src={`${ACUITY_EMBED_BASE_URL}?${queryString}`}
        title="Schedule Appointment"
        width="100%"
        height="100%"
        frameBorder="0"
      />
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      />
    </Box>
  );
};

export default BookAppointment;
