import { Route } from 'react-router-dom';

import LoginCheck from 'app/LoginCheck';
import SentConfirmation from 'app/components/SentConfirmation';
import { PATHS } from 'app/routing/paths';
import SendMessage from 'pages/PostLogin/SendMessage';

const routes = [
  <Route
    path={PATHS.send_message}
    element={<LoginCheck element={<SendMessage />} />}
    key="send_message"
  />,
  <Route
    path={PATHS.send_message_submitted}
    element={
      <SentConfirmation
        title="Message sent"
        subTitle="It will take a few days for the care team to get back to you via phone call or SMS."
      />
    }
    key="send_message_submitted"
  />,
];

export default routes;
