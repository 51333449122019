import { isArray } from 'lodash';
import { IQuestionnaireAnswers } from 'pages/Questionnaire/state';

export const sanitiseAnswers = (answers: IQuestionnaireAnswers) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  Object.keys(answers).reduce((accumulator, key) => {
    const answer = answers[key];

    if (['string', 'number'].includes(typeof answer))
      return { ...accumulator, [key]: answer };

    if (
      typeof answer === 'object' &&
      !isArray(answer) &&
      ['string', 'number'].includes(typeof answer.value)
    )
      return { ...accumulator, [key]: answer.value };

    if (isArray(answer)) {
      return { ...accumulator, [key]: answer };
    }
    return accumulator;
  }, {});
