import Avo from 'analytics/Avo';
import {
  useQuestionId,
  useQuestionnaireId,
} from 'pages/Questionnaire/hooks/routing';
import { useGetAdjacentStepUrls } from 'pages/Questionnaire/hooks/steps';
import { useQuestionProgress } from 'pages/Questionnaire/hooks/useQuestionProgress';
import { useNavigate } from 'react-router-dom';

const useSubmitQuestion = () => {
  const questionnaireId = useQuestionnaireId();
  const { nextStepUrl } = useGetAdjacentStepUrls();
  const questionId = useQuestionId();
  const navigate = useNavigate();
  const { questionIndex, totalQuestionCount } = useQuestionProgress();

  const handleSubmit = () => {
    navigate(nextStepUrl);

    Avo.questionnaireStepNextButtonClicked({
      questionnaireType: questionnaireId,
      stepId: questionId,
      questionIndex,
      totalQuestionCount,
    });
  };
  return handleSubmit;
};

export default useSubmitQuestion;
