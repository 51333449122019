// Constants from the Acuity/Squarespace dashboard
export const ACUITY_OWNER_ID = '25804195';
export const ACUITY_EMBED_BASE_URL =
  'https://app.squarespacescheduling.com/schedule.php';
export const PATIENT_ID_FIELD_ID = 'field:11845188';
export const ACUITY_PATIENT_DASHBOARD_LINK_FIELD_ID = 'field:13848951';
export const ACUITY_PRACTICE_NAME_FIELD_ID = 'field:13848952';
export const APPOINTMENT_GROUP_TYPES: { [key: string]: string } = {
  hca: '32536156',
  clinician: '32219304',
};
