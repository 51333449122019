import { Box, Tab, Tabs } from '@mui/material';
import { ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import PageLayout from '../PageLayout';
import { TabDefinition } from '../TabDefinition';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  isShown: boolean;
}

const TabPanel = ({ children, isShown, index }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={!isShown}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {isShown && <Box>{children}</Box>}
  </Box>
);

type Props = {
  pages: TabDefinition[];
  currentTabIndex: number;
};

const TopTabs = ({ pages, currentTabIndex }: Props) => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      {{
        header: (
          <Header>
            <Tabs
              value={currentTabIndex}
              aria-label="Page tabs"
              sx={{
                '& .MuiTabs-flexContainer': {
                  display: 'flex',
                  justifyContent: 'center',
                },
                '& .MuiButtonBase-root': {
                  height: '56px',
                  width: '120px',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  color: 'colors.black',
                  textTransform: `${'capitalize'} !important`,
                },
                '& .Mui-selected': {
                  color: 'colors.blue',
                  fontSize: 16,
                },
                '& .MuiTabs-indicator': {
                  borderRadius: 0,
                  height: '4px',
                },
              }}
            >
              {pages.map((page, index) => (
                <Tab
                  onClick={() => navigate(page.url)}
                  label={page.title}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                  aria-label={`${page.title} tab`}
                  key={page.title}
                />
              ))}
            </Tabs>
          </Header>
        ),
        body: pages.map((page, i) => (
          <TabPanel key={page.title} index={i} isShown={currentTabIndex === i}>
            {page.page}
          </TabPanel>
        )),
      }}
    </PageLayout>
  );
};

export default TopTabs;
