import Avo from 'analytics/Avo';
import {
  useQuestionId,
  useQuestionnaireId,
} from 'pages/Questionnaire/hooks/routing';
import { useGetAdjacentStepUrls } from 'pages/Questionnaire/hooks/steps';
import { useQuestionProgress } from 'pages/Questionnaire/hooks/useQuestionProgress';
import { useNavigate } from 'react-router-dom';

const useBackClick = () => {
  const questionnaireId = useQuestionnaireId();
  const { previousStepUrl } = useGetAdjacentStepUrls();
  const questionId = useQuestionId();
  const navigate = useNavigate();
  const { questionIndex, totalQuestionCount } = useQuestionProgress();

  const handleClickBack = () => {
    navigate(previousStepUrl);
    Avo.questionnaireStepBackButtonClicked({
      questionnaireType: questionnaireId,
      stepId: questionId,
      questionIndex,
      totalQuestionCount,
    });
  };

  return handleClickBack;
};

export default useBackClick;
