import { IQuestion } from '../api';
import ECommonStep from '../types/ECommonStep';
import { IStep } from '../types/IStep';

export default function getStepsFromQuestions(questions: IQuestion[]): IStep[] {
  return [
    { _id: ECommonStep.START, isCommonStep: true },
    { _id: ECommonStep.DISCLAIMER, isCommonStep: true },
    ...questions,
    { _id: ECommonStep.REVIEW, isCommonStep: true },
    { _id: ECommonStep.COMPLETED, isCommonStep: true },
  ];
}
