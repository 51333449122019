import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';

interface Props {
  buttonText: string;
  buttonClicked: MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function SimpleHeaderBar({
  buttonText,
  buttonClicked,
}: Props): ReactElement {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px',
          width: '100%',
          height: '56px',
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={buttonClicked}
          startIcon={<ArrowBackIosNew fontSize="small" />}
          sx={{ marginLeft: '-16px' }}
        >
          {buttonText}
        </Button>
      </Box>

      <Divider />
    </>
  );
}
