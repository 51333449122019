import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { linkToHomepage } from 'app/routing/links';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleCancel: () => void;
};

const CancelModal = ({ open, handleClose, handleCancel }: Props) => (
  <Dialog
    open={open}
    onClose={handleClose}
    data-testid="cancel_modal_content"
    maxWidth="xs"
    fullWidth
  >
    <DialogTitle
      textAlign="center"
      component="h1"
      variant="body1"
      fontWeight="bold"
    >
      Warning
    </DialogTitle>
    <DialogContent>
      <DialogContentText textAlign="center" variant="body2">
        If you cancel now, your answers won’t be saved and you’ll have to
        restart.
      </DialogContentText>
    </DialogContent>

    <Divider />

    <Stack spacing="0px" direction="row">
      <Button
        sx={{ width: '50%', paddingY: '12px' }}
        variant="text"
        onClick={handleClose}
      >
        Cancel
      </Button>

      <Box>
        <Divider orientation="vertical" />
      </Box>

      <Button
        component={Link}
        to={linkToHomepage()}
        onClick={handleCancel}
        sx={{ width: '50%', paddingY: '12px' }}
        variant="text"
      >
        Confirm
      </Button>
    </Stack>
  </Dialog>
);

export default CancelModal;
