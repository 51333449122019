import { ReactElement, useEffect } from 'react';
import { Navigate, useLocation, Location } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  suveraApiAccessTokenSelector,
  isPatientLoggedInSelector,
} from './state';
import { logOutWithToken, hasJwtExpired } from './tokens';

type LoginCheckParams = { element: ReactElement };

const loginUrlWithRedirectParam = (location: Location) => {
  const redirectURL = location.pathname + location.search + location.hash;
  return `/login?redirect=${encodeURIComponent(redirectURL)}`;
};

const LoginCheck = ({ element }: LoginCheckParams) => {
  const isPatientLoggedIn = useRecoilValue(isPatientLoggedInSelector);
  const accessToken = useRecoilValue(suveraApiAccessTokenSelector) as string;
  const location = useLocation();

  useEffect(() => {
    if (accessToken && hasJwtExpired(accessToken)) {
      logOutWithToken(loginUrlWithRedirectParam(location));
    }
  }, [accessToken, location]);

  if (isPatientLoggedIn && accessToken && !hasJwtExpired(accessToken))
    return element;

  if (accessToken && hasJwtExpired(accessToken)) {
    return null;
  }

  return <Navigate to={loginUrlWithRedirectParam(location)} />;
};

export default LoginCheck;
