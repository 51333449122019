import { Box, Button, Grid, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Checkbox from 'app/components/Checkbox';
import Loading from 'app/components/Loading';
import { linkToSubmitBPSubmitted } from 'app/routing/links';
import { logSentryError } from 'app/sentry';
import {
  patientDetailsSelector,
  suveraApiAccessTokenSelector,
} from 'app/state';

import { usePostQuestionnaireComplete } from 'pages/Questionnaire/hooks/usePostQuestionnaireComplete';
import { postSubmissionPageTextAtom } from 'pages/Questionnaire/state';
import { BpReadingValues } from '../types';
import ConfirmationRow from './ConfirmationRow';

type Props = {
  values: BpReadingValues;
  goBackToEditScreen: () => void;
};

const BpSubmissionConfirmation = ({
  values,
  goBackToEditScreen,
}: Props): ReactElement => {
  const questionnaireId = 'blood_pressure';
  const loggedInPatient = useRecoilValue(patientDetailsSelector);
  const accessToken = useRecoilValue(suveraApiAccessTokenSelector);

  const [spinner, setSpinner] = useState<boolean>(false);

  const navigate = useNavigate();

  const postQuestionnaireComplete = usePostQuestionnaireComplete();
  const setPageText = useSetRecoilState(postSubmissionPageTextAtom);

  async function submitReadings() {
    if (!loggedInPatient || !accessToken) return;

    setSpinner(true);

    const answers = {
      blood_pressure_readings: [
        {
          sys: Number(values.first.sys.value),
          dia: Number(values.first.dia.value),
          pulse: Number(values.first.pulse.value),
        },
        {
          sys: Number(values.second.sys.value),
          dia: Number(values.second.dia.value),
          pulse: Number(values.second.pulse.value),
        },
        {
          sys: Number(values.third.sys.value),
          dia: Number(values.third.dia.value),
          pulse: Number(values.third.pulse.value),
        },
      ],
    };

    await postQuestionnaireComplete
      .mutateAsync({
        questionnaireId,
        answers,
      })
      .then((result) => {
        const postSubmissionPageText =
          result.data.result?.postSubmissionPageText;
        if (postSubmissionPageText !== undefined) {
          setPageText(postSubmissionPageText);
        }
        navigate(linkToSubmitBPSubmitted());
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          logSentryError(error);
        } else {
          throw error;
        }
      });
  }

  const [confirmedReadings, setConfirmedReadings] = useState(false);

  if (spinner) return <Loading />;

  return (
    <Grid container rowSpacing="24px">
      <Grid container item xs={12} rowSpacing="8px">
        <Grid item xs={12}>
          <Typography variant="h2" component="h1">
            Review your recordings
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            Make sure the readings below are correct
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <ConfirmationRow
          title="First reading"
          systolic={values.first.sys.value || ''}
          diastolic={values.first.dia.value || ''}
          pulse={values.first.pulse.value || ''}
        />
      </Grid>

      <Grid container item xs={12}>
        <ConfirmationRow
          title="Second reading"
          systolic={values.second.sys.value || ''}
          diastolic={values.second.dia.value || ''}
          pulse={values.second.pulse.value || ''}
        />
      </Grid>

      <Grid container item xs={12}>
        <ConfirmationRow
          title="Third reading"
          systolic={values.third.sys.value || ''}
          diastolic={values.third.dia.value || ''}
          pulse={values.third.pulse.value || ''}
        />
      </Grid>

      <Grid container item xs={12} rowSpacing="8px">
        <Grid item xs={12}>
          <Button
            variant="text"
            size="large"
            onClick={() => {
              goBackToEditScreen();
            }}
            sx={{
              marginLeft: '-16px',
            }}
          >
            Edit readings
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox
              label="I confirm that these are the correct readings"
              checked={confirmedReadings}
              onChange={(e) => setConfirmedReadings(e.target.checked)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={!confirmedReadings}
          onClick={submitReadings}
        >
          Submit readings
        </Button>
      </Grid>
    </Grid>
  );
};

export default BpSubmissionConfirmation;
