import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { pipe, toSafeInteger } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { IQuestionCalculator } from 'pages/Questionnaire/api';
import BackButton from 'pages/Questionnaire/components/BackButton';
import NextButton from 'pages/Questionnaire/components/NextButton';
import ButtonRow from 'pages/Questionnaire/components/ButtonRow';

import { useAnswerState } from '../hooks/useAnswerState';
import useBackClick from '../hooks/useBackClick';
import useSubmitQuestion from '../hooks/useSubmitQuestion';
import { convertToKilograms } from '../utils/convertToKilograms';
import { convertToStonesAndPounds } from '../utils/convertToStonesAndPounds';
import QuestionLayout from './QuestionLayout';
import UnitToggle from './UnitToggle';
import WeightInputInKilograms from './WeightInputInKilograms';
import WeightInputInPounds from './WeightInputInPounds';

type Props = {
  question: IQuestionCalculator;
  nextStepUrl: string;
};

const formatStonesAndPounds = ([stones, pounds]: [number, number]) =>
  `${stones}st ${pounds}lb`;

const formatWeightImperial = pipe(
  convertToStonesAndPounds,
  formatStonesAndPounds,
);

const ZDecodedError = z.union([
  z
    .tuple([
      z
        .object({ code: z.literal('too_big'), maximum: z.number() })
        .passthrough(),
    ])
    .rest(z.any()),
  z
    .tuple([
      z
        .object({ code: z.literal('too_small'), minimum: z.number() })
        .passthrough(),
    ])
    .rest(z.any()),
  z.tuple([z.object({ code: z.string() }).passthrough()]).rest(z.any()),
]);

const translateZodError = (errorJson: string) => {
  if (!errorJson) return '';
  const parsedErrorJson = ZDecodedError.safeParse(JSON.parse(errorJson));
  if (parsedErrorJson.success !== true) {
    return '';
  }
  const decodedError = parsedErrorJson.data[0];
  switch (decodedError.code) {
    case 'too_big': {
      return `Must be less than ${formatWeightImperial(decodedError.maximum)}`;
    }
    case 'too_small': {
      return `Must be greater than ${formatWeightImperial(
        decodedError.minimum,
      )}`;
    }
    default: {
      captureException(new Error(`Unknown validation error - ${errorJson}`));
      return 'Invalid weight';
    }
  }
};

type FormikValues = {
  kg: string;
  st: string;
  lb: string;
  unit: 'metric' | 'imperial';
};

const ZMetricWeight = (schema: IQuestionCalculator['schema']) =>
  z.coerce
    .number()
    .min(schema.minimum, `Must be greater than ${schema.minimum}kg`)
    .max(schema.maximum, `Must be less than ${schema.maximum}kg`);

const validateFormValues =
  (schema: IQuestionCalculator['schema']) => (values: FormikValues) => {
    if (values.unit === 'imperial') {
      if (values.st === '' && values.lb === '') {
        return {};
      }

      const metricValue = convertToKilograms(
        toSafeInteger(values.st),
        toSafeInteger(values.lb),
      );

      const metricValueParsed = ZMetricWeight(schema).safeParse(metricValue);
      if (metricValueParsed.success === false) {
        return {
          st: translateZodError(metricValueParsed.error.message),
          lb: translateZodError(metricValueParsed.error.message),
        };
      }
    }
    return {};
  };

const createZodSchema = (schema: IQuestionCalculator['schema']) =>
  z.object({
    kg: ZMetricWeight(schema).optional(),
    lb: z.coerce.number().min(0).max(13, 'Cannot be more than 13lb').optional(),
  });

export default function WeightCalculator({ question, nextStepUrl }: Props) {
  const metricId = question.metric;
  const [answer, setAnswer] = useAnswerState(metricId);

  const handleClickBack = useBackClick();

  const handleSubmit = useSubmitQuestion();

  const zodSchemaForForm = useMemo(
    () => createZodSchema(question.schema),
    [question],
  );

  const validateForm = useCallback(
    (values: FormikValues) => {
      return validateFormValues(question.schema)(values);
    },
    [question],
  );

  return (
    <QuestionLayout question={question} nextStepUrl={nextStepUrl}>
      <Formik
        initialValues={{
          kg: answer.value?.toString() || '',
          st: answer.valueInStones?.toString() || '',
          lb: answer.valueInPounds?.toString() || '',
          unit: answer.unit || 'metric',
        }}
        validationSchema={toFormikValidationSchema(zodSchemaForForm)}
        validateOnChange={false}
        validate={validateForm}
        onSubmit={({ unit, kg, st, lb }) => {
          const valueInMetric =
            unit === 'imperial'
              ? convertToKilograms(toSafeInteger(st), toSafeInteger(lb))
              : toSafeInteger(kg);
          setAnswer({
            unit,
            value: valueInMetric || undefined,
            valueInStones: toSafeInteger(st),
            valueInPounds: toSafeInteger(lb),
          });
          return handleSubmit();
        }}
      >
        {(formikProps) => {
          return (
            <Form style={{ marginTop: '8px' }}>
              <Stack
                spacing={{ xs: '20px', sm: '28px' }}
                paddingBottom={{ xs: '82px', sm: '138px' }}
              >
                {formikProps.values.unit === 'metric' ? (
                  <WeightInputInKilograms />
                ) : (
                  <WeightInputInPounds />
                )}
                <Field name="unit">
                  {(fieldProps: FieldProps<FormikValues['unit']>) => (
                    <UnitToggle
                      value={fieldProps.field.value}
                      onChange={(value) => {
                        return fieldProps.form.setValues({
                          unit: value,
                          kg: '',
                          st: '',
                          lb: '',
                        });
                      }}
                      metricUnit="kg"
                      imperialUnit="lb"
                    />
                  )}
                </Field>
              </Stack>
              <ButtonRow>
                <BackButton onClick={handleClickBack} />
                <NextButton />
              </ButtonRow>
            </Form>
          );
        }}
      </Formik>
    </QuestionLayout>
  );
}
