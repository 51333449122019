import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode, Suspense } from 'react';
import { BrowserRouter, MemoryRouter, Routes } from 'react-router-dom';
import { theme } from '@suvera/theme';

import PageTracker from 'analytics/PageTracker';
import ReactQueryProvider from 'app/ReactQueryProvider';

import ErrorBoundary from './ErrorBoundary';
import Loading from './components/Loading';
import routes from './routing/routes';
import useDetectExpiredTokens from './useDetectExpiredTokens';

const App: FC<{ url?: string; children?: ReactNode }> = ({
  children = null,
  url,
}) => {
  useDetectExpiredTokens();
  const RouterComponent = url ? MemoryRouter : BrowserRouter;
  const routerProps = url ? { initialEntries: [url] } : {};
  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <ReactQueryProvider>
            <RouterComponent {...routerProps}>
              <Suspense fallback={<Loading />}>
                {children || (
                  <>
                    <Routes>{routes}</Routes>
                    <PageTracker />
                  </>
                )}
              </Suspense>
            </RouterComponent>
          </ReactQueryProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};

export default App;
