import { Box, SxProps, Theme } from '@mui/material';
import { ReactElement, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Loading from 'app/components/Loading';
import RemoteClinicLogo from 'app/components/RemoteClinicLogo';
import { setupNavStyle } from 'app/components/TopNavigation/index.styled';
import { loggedInPatientAppointmentsSelector } from 'app/state';

const EmbeddedRescheduleFrame = (): ReactElement => {
  const { appointmentID } = useParams();
  const patientAppointments = useRecoilValue(
    loggedInPatientAppointmentsSelector,
  );
  const matchingAppointment = patientAppointments?.find(
    (appointment) => appointment._id === appointmentID,
  );
  return (
    <iframe
      src={matchingAppointment?.rescheduling_link}
      title="Rechedule Appointment"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

const RescheduleAppointment = (): ReactElement => {
  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={setupNavStyle as SxProps<Theme>} position="fixed" top={0}>
        <RemoteClinicLogo />
      </Box>
      <Suspense fallback={<Loading />}>
        <EmbeddedRescheduleFrame />
      </Suspense>
    </Box>
  );
};

export default RescheduleAppointment;
