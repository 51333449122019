export const convertToFeetAndInches = (valueInCentimetres: number) => {
  const unroundedFeet = (valueInCentimetres * 0.3937) / 12;

  const feet = Math.floor(unroundedFeet);
  const inches = Math.round((unroundedFeet - feet) * 12);

  if (inches === 12) return [feet + 1, 0];

  return [feet, inches];
};
