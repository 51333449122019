import { Box, Divider } from '@mui/material';
import { PropsWithChildren } from 'react';

import RemoteClinicLogo from 'app/components/RemoteClinicLogo';
import { ReactComponent as SuveraLogo } from '../svgs/suvera-logo.svg';
import { ReactComponent as NHSLogo } from '../svgs/NHS-logo.svg';

const Header = ({ children }: PropsWithChildren) => {
  return (
    <Box bgcolor="colors.white">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        height="56px"
        paddingX={{ xs: '16px', md: '56px' }}
        paddingY={{ xs: '6px', md: '21px' }}
        gap="20px"
      >
        <Box display="flex" alignItems="center" gap="8px">
          <SuveraLogo title="Suvera logo" />
          <Box>
            <RemoteClinicLogo />
          </Box>
        </Box>
        {children}
        <Box display="flex" alignItems="center">
          <NHSLogo title="NHS logo" />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default Header;
