import { Box, Button, MobileStepper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { linkToHelp } from 'app/routing/links';

import { ArrowBackIosNew } from '@mui/icons-material';
import { StepConfig } from '../consts';

interface Props {
  stepConfig: StepConfig;
}

const StepContent = ({ stepConfig }: Props) => {
  const {
    image,
    title,
    altText,
    body,
    position,
    getNextStep,
    getPreviousStep,
    totalSteps = 4,
  } = stepConfig;
  const nextStep = getNextStep();
  const previousStep = getPreviousStep();
  const navigate = useNavigate();

  return (
    <Stack spacing="16px">
      <Typography variant="h2" component="h1">
        {title}
      </Typography>

      <Box
        component="img"
        sx={{
          content: `url(${image})`,
          alignSelf: 'center',
          width: '100%',
          borderRadius: '8px',
          objectFit: 'cover',
        }}
        alt={altText}
      />

      <Typography variant="body2" dangerouslySetInnerHTML={{ __html: body }} />

      <Stack direction="row" spacing="8px">
        {previousStep ? (
          <Button
            variant="outlined"
            size="small"
            sx={{ maxWidth: '48px', minWidth: 'unset' }}
            onClick={() => {
              navigate(previousStep);
            }}
          >
            <ArrowBackIosNew />
          </Button>
        ) : null}

        {nextStep ? (
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => navigate(nextStep)}
          >
            Next step
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => navigate(linkToHelp())}
          >
            I understand
          </Button>
        )}
      </Stack>

      <MobileStepper
        variant="dots"
        backButton={null}
        nextButton={null}
        steps={totalSteps}
        activeStep={position}
        position="static"
        sx={{ alignSelf: 'center' }}
      />
    </Stack>
  );
};
export default StepContent;
