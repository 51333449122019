import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useQuestionnaire } from '../hooks/selectors';
import getQuestionnaireProgress from '../state/getQuestionnaireProgress';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#BEC1C9',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: '#2FC96D',
  },
}));

type Props = { stepId: string };

const QuestionnaireProgressBar = ({ stepId }: Props) => {
  const questionnaire = useQuestionnaire();
  const { currentStepCount, totalStepCount } = getQuestionnaireProgress(
    stepId,
    questionnaire,
  );

  return (
    <Stack direction="row" alignItems="center" spacing="16px">
      <Box flex={1}>
        <StyledLinearProgress
          sx={{
            height: 12,
            borderRadius: 6,
          }}
          variant="determinate"
          value={(currentStepCount / totalStepCount) * 100}
        />
      </Box>

      <Typography variant="body2" fontWeight="bold" component="span">
        {currentStepCount}/{totalStepCount}
      </Typography>
    </Stack>
  );
};

export default QuestionnaireProgressBar;
