import { useRecoilValue } from 'recoil';

import { useQuestionId, useQuestionnaireId } from './routing';

import {
  selectQuestionById,
  selectQuestionnaire,
} from '../state/questionnaire';

export const useQuestionnaire = () => {
  const questionnaireId = useQuestionnaireId();
  return useRecoilValue(selectQuestionnaire(questionnaireId));
};

export const useCurrentQuestion = () => {
  const questionnaireId = useQuestionnaireId();
  const questionId = useQuestionId();
  const question = useRecoilValue(
    selectQuestionById({ questionnaireId, questionId }),
  );

  return question;
};
