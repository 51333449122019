import * as Inspector from 'avo-inspector';

import Avo from './Avo';

export const getAvoCodegenEnv = () => {
  switch (process.env.REACT_APP_SST_STAGE) {
    case 'production': {
      return Avo.AvoEnv.Prod;
    }
    default: {
      return Avo.AvoEnv.Dev;
    }
  }
};

export const getAvoInspectorEnv = () => {
  switch (process.env.REACT_APP_SST_STAGE) {
    case 'production': {
      return Inspector.AvoInspectorEnv.Prod;
    }
    case 'demo': {
      return Inspector.AvoInspectorEnv.Staging;
    }
    default: {
      return Inspector.AvoInspectorEnv.Dev;
    }
  }
};
