import { isPast, fromUnixTime } from 'date-fns';
import { jwtDecode } from 'jwt-decode';

import * as Avo from 'analytics/Avo';

const SESSION_STORAGE_KEY = 'suveraApiAuthToken';

type DecodedJwt = {
  exp: number;
};

interface SuveraApiAuthToken {
  access_token: string;
}

export const loadPersistedSuveraApiAuthToken = () => {
  const authToken = sessionStorage.getItem('suveraApiAuthToken');
  if (!authToken) return;
  try {
    // eslint-disable-next-line consistent-return
    return JSON.parse(authToken) as SuveraApiAuthToken;
    // eslint-disable-next-line no-empty
  } catch {}
};

export const persistToken = (response: SuveraApiAuthToken) => {
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(response));
};

const clearToken = () => {
  sessionStorage.removeItem('suveraApiAuthToken');
};

export const hasJwtExpired = (jwt: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const decodedAccesToken: DecodedJwt = jwtDecode(jwt);
  return isPast(fromUnixTime(decodedAccesToken.exp));
};

export const logOutWithToken = (redirectTo = '/') => {
  clearToken();
  Avo.userSignedOut();
  window.location.assign(redirectTo);
};
