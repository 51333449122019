import { Typography } from '@mui/material';

import PageLayout from 'app/components/PageLayout';
import QuestionnaireHeader from 'pages/Questionnaire/components/QuestionnaireHeader';
import { useQuestionId } from 'pages/Questionnaire/hooks/routing';
import { useCurrentQuestion } from 'pages/Questionnaire/hooks/selectors';
import { useGetAdjacentStepUrls } from 'pages/Questionnaire/hooks/steps';

import SingleSelectQuestion from './components/SingleSelectQuestion';
import HeightCalculator from './components/HeightCalculator';
import WeightCalculator from './components/WeightCalculator';
import MultiSelectQuestion from './components/MultiSelectQuestion';

const QuestionnaireQuestionContent = () => {
  const question = useCurrentQuestion();

  const { nextStepUrl } = useGetAdjacentStepUrls();

  if (question.questionType === 'single_select') {
    return (
      <SingleSelectQuestion question={question} nextStepUrl={nextStepUrl} />
    );
  }

  if (question.questionType === 'multi_select') {
    return (
      <MultiSelectQuestion question={question} nextStepUrl={nextStepUrl} />
    );
  }

  if (question.questionType === 'height_calculator')
    return <HeightCalculator question={question} nextStepUrl={nextStepUrl} />;

  if (question.questionType === 'weight_calculator')
    return <WeightCalculator question={question} nextStepUrl={nextStepUrl} />;

  return <Typography>Question type not supported</Typography>;
};

const QuestionnaireQuestion = () => {
  const questionId = useQuestionId();

  return (
    <PageLayout>
      {{
        header: <QuestionnaireHeader stepId={questionId} />,
        body: <QuestionnaireQuestionContent />,
      }}
    </PageLayout>
  );
};

export default QuestionnaireQuestion;
