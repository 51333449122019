// Generated by Avo VERSION 120.0.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "YHQNWqneahuBQlpt0jfK";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "GWJtT6HtfZHLxiHvWbnp",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "gSw6fFLOUFl03H8RlhRp",
          "se": (new Date()).toISOString(),
          "so": "EpmwMr2uB",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "GWJtT6HtfZHLxiHvWbnp",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "gSw6fFLOUFl03H8RlhRp",
          "se": (new Date()).toISOString(),
          "so": "EpmwMr2uB",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "gSw6fFLOUFl03H8RlhRp",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const Unit = {
  'METRIC': 'metric',
  'IMPERIAL': 'imperial',
} as const;
export type UnitType = typeof Unit;
export type UnitValueType = UnitType[keyof UnitType];

export const Location = {
  'SUBMIT_BP': 'submit_bp',
  'QUESTIONNAIRE_LIFESTYLE': 'questionnaire_lifestyle',
  'QUESTIONNAIRE_ASTHMA': 'questionnaire_asthma',
} as const;
export type LocationType = typeof Location;
export type LocationValueType = LocationType[keyof LocationType];

export const Tasks = {
  'QUESTIONNAIRE_LIFESTYLE': 'questionnaire_lifestyle',
  'SUBMIT_BP': 'submit_bp',
  'QUESTIONNAIRE_ASTHMA': 'questionnaire_asthma',
} as const;
export type TasksType = typeof Tasks;
export type TasksValueType = TasksType[keyof TasksType];

let SegmentPatientWebApp: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  SegmentPatientWebAppDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    SegmentPatientWebApp = SegmentPatientWebAppDestination;
    if (__AVO_ENV__ === 'prod') {
      SegmentPatientWebApp && SegmentPatientWebApp.make && SegmentPatientWebApp.make(__AVO_ENV__, "pGZ8K3mJxDnQOSGLDCg5DVwbdjtfGGTk");
    } else if (__AVO_ENV__ === 'dev') {
      SegmentPatientWebApp && SegmentPatientWebApp.make && SegmentPatientWebApp.make(__AVO_ENV__, "NjYc0HSjNI27U7wyEMNDsfkGUNODtMBp");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Segment - Patient Web App. Head to destination settings in Avo to set a staging key.");
      SegmentPatientWebApp && SegmentPatientWebApp.make && SegmentPatientWebApp.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface PageViewedProperties {
  customDestinationPageName_: string;
}
/**
 * page_viewed: Segment event to track page views
 *
 * @param properties the properties associatied with this event
 * @param properties.customDestinationPageName_: Name of the page or screen
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/LEPzAikjq0}
 */
export function pageViewed(properties: PageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LEPzAikjq0", "821da72e15131ebee61ab3c453c4dad0325d90c176945790e5a070b757fde58b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("page_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LEPzAikjq0", "page_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination SegmentPatientWebApp
    if (SegmentPatientWebApp.logPage) {
      SegmentPatientWebApp.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

/**
 * user_sign_in_started: When a user started to sign in
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/4P3o1UnUQQ}
 */
export function userSignInStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4P3o1UnUQQ", "501bc57f305aeca19a9aeff578e85be422820d07c775c152ca26e0051eff22f2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_sign_in_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4P3o1UnUQQ", "user_sign_in_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_sign_in_started", eventProperties, "4P3o1UnUQQ", "501bc57f305aeca19a9aeff578e85be422820d07c775c152ca26e0051eff22f2");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("user_sign_in_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * user_sign_in_completed: When a user completed signing in
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/-GqF0JXrRd}
 */
export function userSignInCompleted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-GqF0JXrRd", "e94cb26e512cd77fce14fb045f2ec368156a17aecf2f72c42829230e523c3845", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_sign_in_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-GqF0JXrRd", "user_sign_in_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_sign_in_completed", eventProperties, "-GqF0JXrRd", "e94cb26e512cd77fce14fb045f2ec368156a17aecf2f72c42829230e523c3845");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("user_sign_in_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UserIdentifiedProperties {
  userId_: string;
}
/**
 * user_identified: Segment event to identify who the subsequent events came from
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/P2HUZdS0TV}
 */
export function userIdentified(properties: UserIdentifiedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "P2HUZdS0TV", "20678716a60eea4c814846cd11d8e41d8491b4ee35f7bf7ae05719a366f7f52f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_identified", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("P2HUZdS0TV", "user_identified", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.identify(properties.userId_);
  } else {
    // do nothing
  }
}

/**
 * user_signed_out: When a user signed out
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/rmKPeo_ncL}
 */
export function userSignedOut() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rmKPeo_ncL", "bf0acc43472ccea5cdc9d31b2ceec41afe1a207ee28eeaf0d79e91551587ef6d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_signed_out", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rmKPeo_ncL", "user_signed_out", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_signed_out", eventProperties, "rmKPeo_ncL", "bf0acc43472ccea5cdc9d31b2ceec41afe1a207ee28eeaf0d79e91551587ef6d");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("user_signed_out", (Object as any).assign({}, eventProperties));
    SegmentPatientWebApp.unidentify();
  } else {
    // do nothing
  }
}

/**
 * submit_bp_form_started: Patient clicked the "Start" button on the PWA or Care Team Dashboard for submitting a set of BP readings
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/w0MCXkSDRI}
 */
export function submitBpFormStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "w0MCXkSDRI", "f96609b6d5cf015caca829166bff6ffe7ca51e7558aeea7976fb7dd48a0d6201", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("submit_bp_form_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("w0MCXkSDRI", "submit_bp_form_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("submit_bp_form_started", eventProperties, "w0MCXkSDRI", "f96609b6d5cf015caca829166bff6ffe7ca51e7558aeea7976fb7dd48a0d6201");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("submit_bp_form_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * submit_bp_form_completed: Patient clicked the "Submit" button on the PWA or Care Team Dashboard for submitting a set of BP readings
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/JYaqutfrVZ}
 */
export function submitBpFormCompleted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "JYaqutfrVZ", "d147054ab67659808f9908913e94651c4dd0f449b9293c143d79c2cc32773357", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("submit_bp_form_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("JYaqutfrVZ", "submit_bp_form_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("submit_bp_form_completed", eventProperties, "JYaqutfrVZ", "d147054ab67659808f9908913e94651c4dd0f449b9293c143d79c2cc32773357");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("submit_bp_form_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UserSignInFailedProperties {
  reason: string;
}
/**
 * user_sign_in_failed: When a user failed their sign in
 *
 * @param properties the properties associatied with this event
 * @param properties.reason: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/-ty77TXDNK}
 */
export function userSignInFailed(properties: UserSignInFailedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "_aB2sZWoQ", name: "reason", value: properties.reason});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-ty77TXDNK", "20bf948842be6305e7d084a2fb95371e0dba813774e3c8ba5463634ecddc973e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_sign_in_failed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-ty77TXDNK", "user_sign_in_failed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_sign_in_failed", eventProperties, "-ty77TXDNK", "20bf948842be6305e7d084a2fb95371e0dba813774e3c8ba5463634ecddc973e");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("user_sign_in_failed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireCancelModalOpenedProperties {
  questionnaireType: string;
  stepId: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_cancel_modal_opened: Patient clicked the Cancel button in the header to cancel filling in the questionnaire
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/k6_UAlkwF8}
 */
export function questionnaireCancelModalOpened(
  properties: QuestionnaireCancelModalOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "k6_UAlkwF8", "9ee75471b7b7d98dad64de04c855079d59a9a616d2542e6b401780a0f9d32383", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_cancel_modal_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("k6_UAlkwF8", "questionnaire_cancel_modal_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_cancel_modal_opened", eventProperties, "k6_UAlkwF8", "9ee75471b7b7d98dad64de04c855079d59a9a616d2542e6b401780a0f9d32383");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_cancel_modal_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireCancelModalClosedProperties {
  questionnaireType: string;
  stepId: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_cancel_modal_closed: Patient closed the cancel questionnaire modal
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/s434DqywDq}
 */
export function questionnaireCancelModalClosed(
  properties: QuestionnaireCancelModalClosedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "s434DqywDq", "dc2ea93c92292720f54422bafd33ff441e381cad89722b17ee0d38751c515483", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_cancel_modal_closed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("s434DqywDq", "questionnaire_cancel_modal_closed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_cancel_modal_closed", eventProperties, "s434DqywDq", "dc2ea93c92292720f54422bafd33ff441e381cad89722b17ee0d38751c515483");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_cancel_modal_closed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireCancelledProperties {
  questionnaireType: string;
  stepId: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_cancelled: Patient clicked the "Confirm" button in the cancel modal
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/JVQaK8tRNj}
 */
export function questionnaireCancelled(
  properties: QuestionnaireCancelledProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "JVQaK8tRNj", "1881fa2095e97a8d89590215cbbe4e766551654b5b02ec53110b447a1735d16d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_cancelled", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("JVQaK8tRNj", "questionnaire_cancelled", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_cancelled", eventProperties, "JVQaK8tRNj", "1881fa2095e97a8d89590215cbbe4e766551654b5b02ec53110b447a1735d16d");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_cancelled", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireStartedProperties {
  questionnaireType: string;
}
/**
 * questionnaire_started: Patient starts completing the questionnaire
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/5hKbhTmNzW}
 */
export function questionnaireStarted(
  properties: QuestionnaireStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5hKbhTmNzW", "149251632d3400bf5d2600ce870fe7bcb686f9149441e3abfa016cb5db3247a1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5hKbhTmNzW", "questionnaire_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_started", eventProperties, "5hKbhTmNzW", "149251632d3400bf5d2600ce870fe7bcb686f9149441e3abfa016cb5db3247a1");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireCompletedProperties {
  questionnaireType: string;
}
/**
 * questionnaire_completed: Patient completes filling in the questionnaire
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/CZcnY4Zilh}
 */
export function questionnaireCompleted(
  properties: QuestionnaireCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "CZcnY4Zilh", "1c340ff7867a0c5e55d9902f680c449169840c4be9aa39a3d6c3d956ca541b7c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("CZcnY4Zilh", "questionnaire_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_completed", eventProperties, "CZcnY4Zilh", "1c340ff7867a0c5e55d9902f680c449169840c4be9aa39a3d6c3d956ca541b7c");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireStepSkipButtonClickedProperties {
  questionnaireType: string;
  stepId: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_step_skip_button_clicked: Patient clicks the "Skip" button in the questionnaire
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/iMTisrh62g}
 */
export function questionnaireStepSkipButtonClicked(
  properties: QuestionnaireStepSkipButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iMTisrh62g", "ef5679bab6d20b3050387ef22e413aa0fa0cb75b9f7922cb1cbf1d4343f7ebd1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_step_skip_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iMTisrh62g", "questionnaire_step_skip_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_step_skip_button_clicked", eventProperties, "iMTisrh62g", "ef5679bab6d20b3050387ef22e413aa0fa0cb75b9f7922cb1cbf1d4343f7ebd1");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_step_skip_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireStepNextButtonClickedProperties {
  questionnaireType: string;
  stepId: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_step_next_button_clicked: Patient clicks "Next" or "Continue" on a questionnaire step
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/3TN-XR7YKA}
 */
export function questionnaireStepNextButtonClicked(
  properties: QuestionnaireStepNextButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3TN-XR7YKA", "27ca4196f3e1bffbf969ca3fee742b0844c9a9da4fe71baf5c132cf518124701", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_step_next_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3TN-XR7YKA", "questionnaire_step_next_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_step_next_button_clicked", eventProperties, "3TN-XR7YKA", "27ca4196f3e1bffbf969ca3fee742b0844c9a9da4fe71baf5c132cf518124701");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_step_next_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireStepBackButtonClickedProperties {
  questionnaireType: string;
  stepId: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_step_back_button_clicked: Patient clicks the "Back" button to go back a step
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/-wRo3YVVvk}
 */
export function questionnaireStepBackButtonClicked(
  properties: QuestionnaireStepBackButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-wRo3YVVvk", "eb6b471d8414f5df2937068cc5514908c0d00487c007db0dcb843cb740b46b1a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_step_back_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-wRo3YVVvk", "questionnaire_step_back_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_step_back_button_clicked", eventProperties, "-wRo3YVVvk", "eb6b471d8414f5df2937068cc5514908c0d00487c007db0dcb843cb740b46b1a");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_step_back_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireStepOptionSelectedProperties {
  questionnaireType: string;
  stepId: string;
  option: string;
  questionIndex: number | null | undefined;
  totalQuestionCount: number | null | undefined;
}
/**
 * questionnaire_step_option_selected: Patient clicks a single-select option
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 * @param properties.option: no description
 * @param properties.questionIndex: no description
 * @param properties.totalQuestionCount: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/Rb2o6YZPGa}
 */
export function questionnaireStepOptionSelected(
  properties: QuestionnaireStepOptionSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  eventPropertiesArray.push({id: "OE4nXliz1", name: "option", value: properties.option});
  properties.questionIndex !== undefined && properties.questionIndex !== null ?
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: properties.questionIndex}) :
    eventPropertiesArray.push({id: "g7drdJGIL", name: "question_index", value: null});
  properties.totalQuestionCount !== undefined && properties.totalQuestionCount !== null ?
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: properties.totalQuestionCount}) :
    eventPropertiesArray.push({id: "IjXc47R7Sn", name: "total_question_count", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Rb2o6YZPGa", "a7345d220b01b1d1755ce1fee06ebe67231909f3e5f0b1a07b814862c0de9a99", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_step_option_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Rb2o6YZPGa", "questionnaire_step_option_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_step_option_selected", eventProperties, "Rb2o6YZPGa", "a7345d220b01b1d1755ce1fee06ebe67231909f3e5f0b1a07b814862c0de9a99");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_step_option_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireFetchErroredProperties {
  questionnaireType: string;
}
/**
 * questionnaire_fetch_errored: Request to fetch the patient questionnaire has errored
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/ngEOSswUTt}
 */
export function questionnaireFetchErrored(
  properties: QuestionnaireFetchErroredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ngEOSswUTt", "2bd7d549c0ce01c58bfba3c9dd31f00f2b8441226f296e80b1e00a7f8fd23b84", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_fetch_errored", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ngEOSswUTt", "questionnaire_fetch_errored", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_fetch_errored", eventProperties, "ngEOSswUTt", "2bd7d549c0ce01c58bfba3c9dd31f00f2b8441226f296e80b1e00a7f8fd23b84");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_fetch_errored", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireSubmissionErroredProperties {
  questionnaireType: string;
}
/**
 * questionnaire_submission_errored: Request to save the patient submission has errored
 *
 * @param properties the properties associatied with this event
 * @param properties.questionnaireType: Lifestyle or Asthma
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/hyrX9q0k9C}
 */
export function questionnaireSubmissionErrored(
  properties: QuestionnaireSubmissionErroredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hyrX9q0k9C", "a7c9b133b3d2ce0f259c21df725d453b09f5a0c1203f40112f534427e18fcad0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_submission_errored", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hyrX9q0k9C", "questionnaire_submission_errored", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_submission_errored", eventProperties, "hyrX9q0k9C", "a7c9b133b3d2ce0f259c21df725d453b09f5a0c1203f40112f534427e18fcad0");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_submission_errored", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TaskListViewedProperties {
  tasks: TasksValueType[];
}
/**
 * task_list_viewed: patient views the list of outstanding tasks on homepage
 *
 * @param properties the properties associatied with this event
 * @param properties.tasks: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/XK4hy2WoT8}
 */
export function taskListViewed(properties: TaskListViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "on4G2nbq1", name: "tasks", value: properties.tasks});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "XK4hy2WoT8", "329b6bb892eaac107f98f76d21af24f212c57f288fe3a142b2fb871c3a8ca989", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("task_list_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("XK4hy2WoT8", "task_list_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("task_list_viewed", eventProperties, "XK4hy2WoT8", "329b6bb892eaac107f98f76d21af24f212c57f288fe3a142b2fb871c3a8ca989");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("task_list_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HelpButtonClickedProperties {
  location: LocationValueType;
}
/**
 * help_button_clicked: A user clicked on the help button from inside a form
 *
 * @param properties the properties associatied with this event
 * @param properties.location: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/Y-Cz7BW6Sc}
 */
export function helpButtonClicked(properties: HelpButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "eDvSSXfcw", name: "location", value: properties.location});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Y-Cz7BW6Sc", "d02c217c631fb1c65fea6f0029e1d67761eb05f125126be85d8d21bac8ed6ad6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("help_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Y-Cz7BW6Sc", "help_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("help_button_clicked", eventProperties, "Y-Cz7BW6Sc", "d02c217c631fb1c65fea6f0029e1d67761eb05f125126be85d8d21bac8ed6ad6");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("help_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * user_sign_in_validation_error_message_viewed: When input validation fails during user sign in
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/ElZzA5yTxr}
 */
export function userSignInValidationErrorMessageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ElZzA5yTxr", "dcac62af9358ee3b6fcf3b5db6d3d9442ea963f068da192e46b669d63e8382da", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_sign_in_validation_error_message_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ElZzA5yTxr", "user_sign_in_validation_error_message_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_sign_in_validation_error_message_viewed", eventProperties, "ElZzA5yTxr", "dcac62af9358ee3b6fcf3b5db6d3d9442ea963f068da192e46b669d63e8382da");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("user_sign_in_validation_error_message_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuestionnaireUnitToggleClickedProperties {
  unit: UnitValueType;
  questionnaireType: string;
  stepId: string;
}
/**
 * questionnaire_unit_toggle_clicked: When patient clicks the unit toggle in the calculator questions in the questionnaire
 *
 * @param properties the properties associatied with this event
 * @param properties.unit: no description
 * @param properties.questionnaireType: Lifestyle or Asthma
 * @param properties.stepId: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/iXPxApGHkJ}
 */
export function questionnaireUnitToggleClicked(
  properties: QuestionnaireUnitToggleClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "UeIwzFfwB", name: "unit", value: properties.unit});
  eventPropertiesArray.push({id: "8GhIytfEt", name: "questionnaire_type", value: properties.questionnaireType});
  eventPropertiesArray.push({id: "BH1CaKYib", name: "step_id", value: properties.stepId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iXPxApGHkJ", "64fbd5f6738d048b198afb9ac918506375c89f2930109e453e50b0993341df79", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("questionnaire_unit_toggle_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iXPxApGHkJ", "questionnaire_unit_toggle_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("questionnaire_unit_toggle_clicked", eventProperties, "iXPxApGHkJ", "64fbd5f6738d048b198afb9ac918506375c89f2930109e453e50b0993341df79");
    }
    // destination SegmentPatientWebApp
    SegmentPatientWebApp.logEvent("questionnaire_unit_toggle_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  Unit,
  Location,
  Tasks,
  pageViewed,
  userSignInStarted,
  userSignInCompleted,
  userIdentified,
  userSignedOut,
  submitBpFormStarted,
  submitBpFormCompleted,
  userSignInFailed,
  questionnaireCancelModalOpened,
  questionnaireCancelModalClosed,
  questionnaireCancelled,
  questionnaireStarted,
  questionnaireCompleted,
  questionnaireStepSkipButtonClicked,
  questionnaireStepNextButtonClicked,
  questionnaireStepBackButtonClicked,
  questionnaireStepOptionSelected,
  questionnaireFetchErrored,
  questionnaireSubmissionErrored,
  taskListViewed,
  helpButtonClicked,
  userSignInValidationErrorMessageViewed,
  questionnaireUnitToggleClicked,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["pageViewed","userSignInStarted","userSignInCompleted","userIdentified","userSignedOut","submitBpFormStarted","submitBpFormCompleted","userSignInFailed","questionnaireCancelModalOpened","questionnaireCancelModalClosed","questionnaireCancelled","questionnaireStarted","questionnaireCompleted","questionnaireStepSkipButtonClicked","questionnaireStepNextButtonClicked","questionnaireStepBackButtonClicked","questionnaireStepOptionSelected","questionnaireFetchErrored","questionnaireSubmissionErrored","taskListViewed","helpButtonClicked","userSignInValidationErrorMessageViewed","questionnaireUnitToggleClicked"]
