import { Home, Person } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

import BottomTabs from 'app/components/BottomTabs';
import TopTabs from 'app/components/TopTabs';
import { linkToHomepage, linkToProfile } from 'app/routing/links';

import HomeTab from './components/HomeTab';
import ProfileTab from './components/ProfileTab';

const pages = [
  {
    title: 'Home',
    icon: () => <Home />,
    page: <HomeTab />,
    url: linkToHomepage(),
  },
  {
    title: 'Profile',
    icon: () => <Person />,
    page: <ProfileTab />,
    url: linkToProfile(),
  },
];

type Props = {
  currentTabIndex: number;
};

const Homepage = ({ currentTabIndex }: Props) => {
  const mobile = useMediaQuery('(max-width:700px)');

  if (mobile)
    return <BottomTabs currentTabIndex={currentTabIndex} pages={pages} />;
  return <TopTabs currentTabIndex={currentTabIndex} pages={pages} />;
};

export default Homepage;
