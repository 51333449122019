import { Stack, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as PillConfused } from './PillConfused.svg';

type Props = {
  title?: string;
  description?: string;
  buttonText?: string;
  buttonUrl?: string;
  buttonOnClick?: () => void;
};

const ErrorScreen = ({
  title = 'Oops! Something went wrong',
  description = 'Please refresh the page or try again later',
  buttonText = 'Refresh page',
  buttonUrl,
  buttonOnClick,
}: Props) => {
  const getButtonProps = () => {
    if (buttonUrl && buttonOnClick) {
      return { component: Link, to: buttonUrl, onClick: buttonOnClick };
    }
    if (buttonUrl) {
      return { component: Link, to: buttonUrl };
    }
    if (buttonOnClick) {
      return { onClick: buttonOnClick };
    }
    return { onClick: () => window.location.reload() };
  };

  return (
    <Stack height="100%" spacing="32px">
      <Box
        height="100%"
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Stack width="100%" spacing="16px" alignItems="center">
          <Box component={PillConfused} />

          <Typography variant="h2" component="h1" textAlign="center">
            {title}
          </Typography>

          <Typography variant="body2" textAlign="center">
            {description}
          </Typography>
        </Stack>
      </Box>

      <Button
        variant="contained"
        {...getButtonProps()}
        sx={{
          flex: 1,
          padding: '12px',
          fontSize: '16px',
          width: '100%',
        }}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default ErrorScreen;
